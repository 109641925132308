var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "section-mp-broadcast" }, [
    _c(
      "div",
      { staticClass: "mp-broadcast-container" },
      [
        _c("v-container", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _vm.presentation.isPresentationShown
                ? _c("div", { staticClass: "mp-broadcast-block" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 col-lg-6" }, [
                        _c("div", { staticClass: "broadcast-video-block" }, [
                          _c(
                            "div",
                            { staticClass: "broadcast-video" },
                            [_c("PlayerContainer")],
                            1
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "col-12 col-lg-6" }, [
                        _c(
                          "div",
                          { staticClass: "broadcast-chat-block" },
                          [
                            _c("Presentation", {
                              staticClass: "broadcast-chat"
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.chatAvailable,
                                    expression: "chatAvailable"
                                  }
                                ],
                                staticClass: "chat-block-wrapper"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "chat-open-link",
                                    class: { active: _vm.chatOpen }
                                  },
                                  [
                                    _vm.newMessages
                                      ? [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$tc(
                                                    "new comment",
                                                    _vm.newMessages
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("span", {
                                            staticClass: "chat-open-link-new"
                                          })
                                        ]
                                      : [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("chat users tab text")
                                              ) +
                                              " "
                                          )
                                        ],
                                    _c("div", {
                                      staticClass: "chat-open-link__arrow",
                                      on: {
                                        click: function($event) {
                                          _vm.chatOpen = !_vm.chatOpen
                                        }
                                      }
                                    })
                                  ],
                                  2
                                ),
                                _c("v-expand-transition", [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.chatOpen,
                                          expression: "chatOpen"
                                        }
                                      ],
                                      staticClass: "chat-block"
                                    },
                                    [
                                      _c(
                                        "keep-alive",
                                        [
                                          _c("ExpertChat", {
                                            ref: "collapsedChat",
                                            attrs: {
                                              visible: _vm.chatOpen,
                                              "is-presentation-shown":
                                                _vm.presentation
                                                  .isPresentationShown
                                            },
                                            on: {
                                              "unreadMessages:update":
                                                _vm.onUnreadMessages
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                : _c(
                    "div",
                    {
                      staticClass:
                        "mp-broadcast-block mp-broadcast-block--no-presentation"
                    },
                    [
                      _c("div", { staticClass: "row justify-content-center" }, [
                        _c("div", { staticClass: "col-12 col-lg-8" }, [
                          _c("div", { staticClass: "broadcast-video-block" }, [
                            _c(
                              "div",
                              { staticClass: "broadcast-video" },
                              [_c("PlayerContainer")],
                              1
                            )
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.chatAvailable,
                                expression: "chatAvailable"
                              }
                            ],
                            staticClass: "col-12 col-lg-4"
                          },
                          [
                            _c("div", { staticClass: "broadcast-chat-block" }, [
                              _c("div", { staticClass: "chat-block-wrapper" }, [
                                _c(
                                  "div",
                                  { staticClass: "chat-block" },
                                  [
                                    _c(
                                      "keep-alive",
                                      [
                                        _c("ExpertChat", {
                                          on: {
                                            "unreadMessages:update":
                                              _vm.onUnreadMessages
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            ])
                          ]
                        )
                      ])
                    ]
                  )
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("section", { staticClass: "question" }, [
        _c("p", { staticClass: "question__head" }, [
          _vm._v(
            " " +
              _vm._s(_vm._f("upperFirst")(_vm.$t("question"))) +
              " " +
              _vm._s(_vm.currentQuestionNumber + 1) +
              " / " +
              _vm._s(_vm.questions.length) +
              " "
          )
        ]),
        _c("h3", { staticClass: "question__title" }, [
          _vm._v(" " + _vm._s(_vm.currentQuestion.text) + " ")
        ]),
        _c(
          "div",
          { staticClass: "questions-list" },
          _vm._l(_vm.currentQuestion.answerOptions, function(el, idx) {
            return _c(
              "div",
              {
                key: idx,
                staticClass: "questions-list__item",
                class: {
                  right: _vm.selectedAnswer && _vm.isRightAnswer(idx),
                  wrong: _vm.selectedAnswer && idx + 1 === _vm.selectedAnswer
                },
                on: {
                  click: function($event) {
                    return _vm.setAnswer(idx)
                  }
                }
              },
              [
                _c("p", { staticClass: "item__index" }, [
                  _vm._v(" .0" + _vm._s(idx + 1) + " ")
                ]),
                _c("p", { staticClass: "item__title" }, [
                  _vm._v(" " + _vm._s(el.text) + " ")
                ]),
                _vm.selectedAnswer && _vm.selectedAnswer === idx + 1
                  ? _c("p", { staticClass: "item__status" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isRightAnswer(idx)
                              ? _vm.$t("right") + "!"
                              : _vm.$t("wrong") + "!"
                          ) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm.selectedAnswer && _vm.selectedAnswer === idx + 1
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "item__status-icon",
                        attrs: {
                          dark: _vm.isRightAnswer(idx),
                          color: _vm.isRightAnswer(idx) ? "#fff" : "#D156FA"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isRightAnswer(idx) ? "mdi-check" : "mdi-close"
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _c(
              "v-btn",
              {
                staticClass: "next-btn custom-next",
                class: { "active-btn": _vm.selectedAnswer },
                attrs: { disabled: !_vm.selectedAnswer },
                on: { click: _vm.nextQuestion }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.isAllAnswers
                        ? _vm.$t("result")
                        : _vm.$t("next question")
                    ) +
                    " "
                )
              ]
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vuex from 'vuex'
import Vue from 'vue'
import * as api from '@/services/api/expertChat'
import userService from '@/services/user'
import {
  ChatTextMessage,
  ChatMessage,
  ChatQuizAnswer,
  ChatQuizMessage,
  CentrifugeChatEventPayload
} from '@/modules/ExpertChat/types'
import {
  SerializedChatMessage,
  SerializedChatQuizAnswer,
  SerializedChatQuizMessage,
  SerializedChatTextMessage
} from '@/services/api/expertChat/types'
import EventBus from '@/EventBus'
import { UserInstance } from '@/services/user/types'
import { cloneDeep } from 'lodash'

const defaultState = () => ({
  messages: [] as ChatMessage[],
  sharedMessages: [] as ChatMessage[],
  privateRoomId: null as string | null,
  publicRoomId: null as string | null
})

const store = new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',

  state: defaultState(),

  getters: {
    serializeChatEvent: (state, getters) => (data: ChatMessage): SerializedChatMessage => {
      const type = data.type
      switch (type) {
        case 'quiz':
          return getters.serializeQuizChatEvent(data)
        case 'text':
          return getters.serializeTextChatEvent(data)
        default: throw new Error(`${type} not valid message type`)
      }
    },

    serializeTextChatEvent: () => (data: ChatTextMessage): SerializedChatTextMessage => ({
      id: data.id,
      user: {
        id: data.user.id,
        full_name: data.user.fullName
      },
      type: 'text',
      created_at: data.createdAt,
      text: data.payload.text
    }),

    serializeQuizChatEvent: (state, getters) => (data: ChatQuizMessage): SerializedChatQuizMessage => ({
      id: data.id,
      user: {
        id: data.user.id,
        full_name: data.user.fullName
      },
      type: 'quiz',
      answers: data.payload.answers.map(el => getters.serializeQuizAnswer(el)),
      created_at: data.createdAt,
      user_vote: data.userVote,
      text: data.payload.text
    }),

    serializeQuizAnswer: () => (data: ChatQuizAnswer): SerializedChatQuizAnswer => ({
      answer: {
        id: data.id,
        text: data.text,
        position: data.position
      },
      voted: data.votes
    }),

    deserializeChatEvent: (state, getters) =>
      (data: SerializedChatMessage, currentUserId: string): ChatMessage => {
        if (data.type === 'quiz') {
          return getters.deserializeQuizChatEvent(data, currentUserId)
        }
        return getters.deserializeTextChatEvent(data, currentUserId)
      },

    deserializeTextChatEvent: () =>
      (data: SerializedChatTextMessage, currentUserId: string): ChatTextMessage => {
        if (!currentUserId) throw new Error('second argument with user id is required')

        return ({
          id: data.id,
          type: data.type,
          createdAt: data.created_at,
          isCurrentUserOwner: currentUserId === data.user.id,
          payload: {
            text: data.text
          },
          user: {
            id: data.user.id,
            fullName: data.user.full_name
          },
          status: 'success'
        })
      },

    deserializeQuizChatEvent: (state, getters) =>
      (data: SerializedChatQuizMessage, currentUserId: string): ChatQuizMessage => {
        if (!currentUserId) throw new Error('second argument with user id is required')

        const answers = data.answers?.map(el => getters.deserializeQuizAnswer(el))
        const totalVotes = answers.reduce((acc, cur) => acc + cur.votes, 0)

        // Если варианта ответа за который проголосовал пользователь больше нет, то убрать голос
        const userVote = answers.find(el => el.id === data.user_vote)?.id

        return ({
          id: data.id,
          type: data.type,
          createdAt: data.created_at,
          isCurrentUserOwner: currentUserId === data.user.id,
          payload: {
            text: data.text,
            answers,
            totalVotes
          },
          user: {
            id: data.user.id,
            fullName: data.user.full_name
          },
          userVote,
          status: 'success'
        })
      },

    deserializeQuizAnswer: () => (data: SerializedChatQuizAnswer): ChatQuizAnswer => ({
      id: data.answer.id,
      text: data.answer.text,
      position: data.answer.position,
      votes: data.voted
    }),

    getNewMessage: () => (text: string, currentUser: {id: string, fullName: string}): ChatTextMessage => {
      const nowDate = new Date()
      return {
        id: nowDate.getTime().toString(),
        user: {
          id: currentUser.id,
          fullName: currentUser.fullName
        },
        isCurrentUserOwner: true,
        type: 'text',
        payload: {
          text
        },
        createdAt: nowDate.toISOString(),
        status: 'new'
      }
    }
  },

  actions: {
    clearState ({ commit, state }) {
      /* eslint-disable @typescript-eslint/no-use-before-define */
      if (state.publicRoomId) {
        EventBus.unsubscribe(`ws:public:${state.publicRoomId}`, onChatService)
      }
      if (state.privateRoomId) {
        EventBus.unsubscribe(`ws:private:${state.privateRoomId}`, onChatService)
      }
      EventBus.unsubscribe('ws:shared', onSharedChatService)
      /* eslint-enable @typescript-eslint/no-use-before-define */

      commit('clearState')
    },

    async getHistory ({ commit, getters, state }) {
      const [history, rooms] = await Promise.allSettled([api.getHistory(), api.getRoomsList()])
      if (history.status === 'rejected' && history.reason.response.statusText !== 'Gone') {
        throw history.reason
      }
      if (rooms.status === 'rejected') {
        throw rooms.reason
      }
      const data = history.status === 'fulfilled' ? history.value.data : null
      const roomsData = rooms.value.data

      EventBus.emit('chat:available', data != null)
      const currentUserId = await userService.getUser().then((res: UserInstance) => res.id)

      /* eslint-disable @typescript-eslint/no-use-before-define */
      if (state.publicRoomId !== roomsData.public_room_id) {
        if (state.publicRoomId) {
          EventBus.unsubscribe(`ws:public:${state.publicRoomId}`, onChatService)
        }

        if (roomsData.public_room_id) {
          EventBus.subscribe(`ws:public:${roomsData.public_room_id}`, onChatService)
        }
      }

      if (state.privateRoomId !== roomsData.private_room_id) {
        if (state.privateRoomId) {
          EventBus.unsubscribe(`ws:private:${state.privateRoomId}`, onChatService)
        }

        if (roomsData.private_room_id) {
          EventBus.subscribe(`ws:private:${roomsData.private_room_id}`, onChatService)
        }
      }
      /* eslint-enable @typescript-eslint/no-use-before-define */

      commit('setRoomsIds', roomsData)
      if (!data) return

      commit('clearEvents')
      commit(
        'addMessages',
        data.messages.map((el: SerializedChatMessage) => getters.deserializeChatEvent(el, currentUserId))
      )
    },

    async getSharedMessagesHistory ({ commit, getters }) {
      let data = null
      try {
        ({ data } = await api.getSharedMessagesHistory())
      } catch (e) {
        if (e.response.statusText !== 'Gone') {
          throw e
        }
      }
      EventBus.emit('chat:available', data != null)
      if (!data) return

      /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
      EventBus.subscribe('ws:shared', onSharedChatService)

      const currentUserId = await userService.getUser().then((res: UserInstance) => res.id)
      commit(
        'setSharedMessages',
        data.messages.map((el: SerializedChatMessage) => getters.deserializeChatEvent(el, currentUserId))
      )
    },

    async sendMessage ({ commit, getters }, payload: string | ChatTextMessage) {
      const currentUser = await userService.getUser()

      let newMessage = cloneDeep(payload)

      if (typeof newMessage === 'string') {
        newMessage = getters.getNewMessage(payload, currentUser) as ChatTextMessage
        newMessage.status = 'pending'
        commit('addMessages', [newMessage])
      } else {
        newMessage.status = 'pending'
        commit('updateMessage', newMessage)
      }

      try {
        const { data } = await api.sendMessage(getters.serializeTextChatEvent(newMessage))
        const deserialized = getters.deserializeChatEvent(data, currentUser.id)
        commit('updateMessageById', { message: deserialized, id: newMessage.id })
      } catch (err) {
        commit('updateMessage', {
          ...newMessage,
          status: 'error'
        } as ChatMessage)
      }
    },

    async sendSharedMessage ({ commit, getters }, payload: string | ChatTextMessage) {
      const currentUser = await userService.getUser()

      let newMessage = cloneDeep(payload)

      if (typeof newMessage === 'string') {
        newMessage = getters.getNewMessage(payload, currentUser) as ChatTextMessage
        newMessage.status = 'pending'
        commit('setSharedMessages', [newMessage])
      } else {
        newMessage.status = 'pending'
        commit('updateSharedMessage', newMessage)
      }

      try {
        const { data } = await api.sendSharedMessage(getters.serializeTextChatEvent(newMessage))
        const deserialized = getters.deserializeChatEvent(data, currentUser.id)
        commit('updateSharedMessageById', { message: deserialized, id: newMessage.id })
      } catch (err) {
        commit('updateSharedMessage', {
          ...newMessage,
          status: 'error'
        } as ChatMessage)
      }
    },

    async deleteMessage ({ commit, getters }, message: ChatMessage) {
      await api.deleteMessage(getters.serializeTextChatEvent(message))
      commit('deleteMessage', message)
    },

    async deleteSharedMessage ({ commit, getters }, message: ChatMessage) {
      await api.deleteMessage(getters.serializeTextChatEvent(message))
      commit('deleteSharedMessage', message)
    },

    async onReceiveNewMessage ({ commit, getters }, payload: SerializedChatMessage) {
      const currentUser = await userService.getUser()
      const deserialized = getters.deserializeChatEvent(payload, currentUser.id)
      if (deserialized.isCurrentUserOwner) return
      commit('addMessages', [deserialized])
    },

    async onReceiveSharedMessage ({ commit, getters }, payload: SerializedChatMessage) {
      const currentUser = await userService.getUser()
      const deserialized = getters.deserializeChatEvent(payload, currentUser.id)
      if (deserialized.isCurrentUserOwner) return
      commit('setSharedMessages', [deserialized])
    },

    async onReceiveUpdateMessage ({ commit, getters, state }, patch: SerializedChatMessage) {
      const stateMessage = cloneDeep(state.messages.find(el => el.id === patch.id))
      if (!stateMessage) return

      const currentUser = await userService.getUser()

      const updatedMessage = getters.deserializeChatEvent({
        ...getters.serializeChatEvent(stateMessage),
        ...patch
      }, currentUser.id)

      commit('updateMessage', updatedMessage)
    },

    async onReceiveUpdateSharedMessage ({ commit, getters, state }, patch: SerializedChatMessage) {
      const stateMessage = cloneDeep(state.sharedMessages.find(el => el.id === patch.id))
      if (!stateMessage) return

      const currentUser = await userService.getUser()

      const updatedMessage = getters.deserializeChatEvent({
        ...getters.serializeChatEvent(stateMessage),
        ...patch
      }, currentUser.id)

      commit('updateSharedMessageById', { message: updatedMessage, id: updatedMessage.id })
    },

    async onReceiveDeleteMessage ({ commit, getters }, payload: SerializedChatMessage) {
      const currentUser = await userService.getUser()
      const deserialized = getters.deserializeChatEvent(payload, currentUser.id)
      commit('deleteMessage', deserialized)
    },

    async onReceiveDeleteSharedMessage ({ commit, getters }, payload: SerializedChatMessage) {
      const currentUser = await userService.getUser()
      const deserialized = getters.deserializeChatEvent(payload, currentUser.id)
      commit('deleteSharedMessage', deserialized)
    },

    async updateMessage ({ commit, getters }, message: ChatTextMessage) {
      const currentUserId = await userService.getUser().then((res: UserInstance) => res.id)
      const { data } = await api.updateMessage(getters.serializeTextChatEvent(message))
      commit('updateMessage', getters.deserializeTextChatEvent(data, currentUserId))
    },

    async updateSharedMessage ({ commit, getters }, message: ChatTextMessage) {
      const currentUserId = await userService.getUser().then((res: UserInstance) => res.id)
      const { data } = await api.updateMessage(getters.serializeTextChatEvent(message))
      commit('updateSharedMessage', getters.deserializeTextChatEvent(data, currentUserId))
    },

    async sendQuizVote ({ commit, getters }, payload: { quiz: ChatQuizMessage, vote: ChatQuizMessage['userVote'] }) {
      const currentUserId = await userService.getUser().then((res: UserInstance) => res.id)
      if (!payload.quiz.id) throw new Error('quiz id is required')
      if (!payload.vote) throw new Error('vote is required')

      const { data } = await api.voteQuiz(payload.quiz.id, payload.vote)
      commit('updateMessage', getters.deserializeQuizChatEvent(data, currentUserId))
    }
  },

  mutations: {
    clearEvents (state) {
      Vue.set(state, 'messages', [] as ChatMessage[])
    },

    clearState (state) {
      const defaultStateObj = defaultState()
      Object.entries(defaultStateObj).forEach(([key, val]) => {
        if (key !== 'sharedMessages') {
          Vue.set(state, key, val)
        }
      })
    },

    updateMessage (state, message: ChatMessage) {
      const messageIndex = state.messages.findIndex(el => el.id === message.id)
      state.messages.splice(messageIndex, 1, message)
    },

    updateSharedMessage (state, message: ChatMessage) {
      const messageIndex = state.sharedMessages.findIndex(el => el.id === message.id)
      state.sharedMessages.splice(messageIndex, 1, message)
    },

    deleteMessage (state, message: ChatMessage) {
      const messageIndex = state.messages.findIndex(el => el.id === message.id)
      state.messages.splice(messageIndex, 1)
    },

    deleteSharedMessage (state, message: ChatMessage) {
      const messageIndex = state.sharedMessages.findIndex(el => el.id === message.id)
      state.sharedMessages.splice(messageIndex, 1)
    },

    updateMessageById (state, payload: {message: ChatMessage, id: string}) {
      const messageIndex = state.messages.findIndex(el => el.id === payload.id)
      state.messages.splice(messageIndex, 1, payload.message)
    },

    updateSharedMessageById (state, payload: {message: ChatMessage, id: string}) {
      const messageIndex = state.sharedMessages.findIndex(el => el.id === payload.id)
      state.sharedMessages.splice(messageIndex, 1, payload.message)
    },

    addMessages (state, payload: ChatMessage[]) {
      state.messages = payload.filter(msg => !state.messages.find(other => other.id === msg.id))
        .concat(state.messages)
    },

    setSharedMessages (state, payload: ChatMessage[]) {
      state.sharedMessages = payload.filter(msg => !state.sharedMessages.find(other => other.id === msg.id))
        .concat(state.sharedMessages)
    },

    setRoomsIds (state, payload: { private_room_id: string, public_room_id: string }) {
      state.privateRoomId = payload.private_room_id
      state.publicRoomId = payload.public_room_id
    }
  }
})

const onChatService = (payload: CentrifugeChatEventPayload) => {
  switch (payload.data?.event) {
    case 'New message':
      store.dispatch('onReceiveNewMessage', payload.data.payload.message)
      break
    case 'Update message':
      store.dispatch('onReceiveUpdateMessage', payload.data.payload.message)
      break
    case 'Delete message':
      store.dispatch('onReceiveDeleteMessage', payload.data.payload.message)
      break
    case 'Update status':
      EventBus.emit('chat:available', !payload.data.locked)
      break
  }
}

const onSharedChatService = (payload: CentrifugeChatEventPayload) => {
  switch (payload.data?.event) {
    case 'New message':
      store.dispatch('onReceiveSharedMessage', payload.data.payload.message)
      break
    case 'Update message':
      store.dispatch('onReceiveUpdateSharedMessage', payload.data.payload.message)
      break
    case 'Delete message':
      store.dispatch('onReceiveDeleteSharedMessage', payload.data.payload.message)
      break
  }
}

export default store

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "section-mp-knowledge",
      staticStyle: { position: "relative !important" }
    },
    [
      _c("v-container", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "mp-knowledge-top-block" }, [
              _c("div", { staticClass: "sect-count" }, [_vm._v(" .02 ")]),
              _c("h2", { staticStyle: { "max-width": "340px" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("upperFirst")(_vm.$t("test your knowledge"))
                    ) +
                    " "
                )
              ]),
              _c("div", { staticClass: "mp-knowledge-top-bg-text" }, [
                _vm._v(" TEST"),
                _c("span", { staticClass: "d-none d-lg-inline" }, [
                  _vm._v("S & QUIZES")
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("v-container", { staticClass: "knowledge-slider-block-container" }, [
        _c(
          "div",
          { staticClass: "knowledge-slider-block" },
          [
            _c(
              "Slick",
              {
                ref: "carousel",
                staticClass: "knowledge-slider slider",
                attrs: { options: _vm.sliderSettings }
              },
              _vm._l(_vm.items, function(item, index) {
                return _c(
                  "div",
                  {
                    key: "" + index + item.name,
                    staticClass: "knowledge-slide slide"
                  },
                  [
                    !item.status && item.isActive
                      ? _c("div", { staticClass: "knowledge-s-item" }, [
                          _c(
                            "div",
                            {
                              staticClass: "knowledge-s-item__inner",
                              style: {
                                backgroundImage:
                                  "url(" +
                                  (_vm.odd(index)
                                    ? _vm.getLinkByEvent(_vm.knowledgeSItemBg1)
                                    : _vm.getLinkByEvent(
                                        _vm.knowledgeSItemBg2
                                      )) +
                                  "),\n                url(" +
                                  (_vm.odd(index)
                                    ? _vm.knowledgeSItemBgLocal1
                                    : _vm.knowledgeSItemBgLocal2) +
                                  ")\n                "
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "knowledge-s-item__top" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "knowledge-s-item__count" },
                                    [_vm._v(" .0" + _vm._s(index + 1) + " ")]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "knowledge-s-item__content" },
                                [
                                  _c("h3", [
                                    _vm._v(" " + _vm._s(item.name) + " ")
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "knowledge-s-item__text" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.description) + " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "knowledge-s-item__bottom" },
                                [
                                  item.status
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "knowledge-s-item__complete-text text-uppercase"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("test passed")) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "a",
                                        {
                                          staticClass:
                                            "knowledge-s-item__btn white-btn",
                                          on: {
                                            click: function($event) {
                                              return _vm.takeTest(item, index)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("upperFirst")(
                                                  _vm.$t("take the test")
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                ]
                              )
                            ]
                          )
                        ])
                      : _c(
                          "div",
                          {
                            staticClass:
                              "knowledge-s-item knowledge-s-item--complete"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "knowledge-s-item__inner" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "knowledge-s-item__top" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "knowledge-s-item__count"
                                      },
                                      [_vm._v(" .0" + _vm._s(index + 1) + " ")]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "knowledge-s-item__content" },
                                  [
                                    _c("h3", [
                                      _vm._v(" " + _vm._s(item.name) + " ")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "knowledge-s-item__text" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.description) + " "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "knowledge-s-item__bottom" },
                                  [
                                    item.status
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "knowledge-s-item__complete-text text-uppercase"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("test passed")) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "knowledge-s-item__complete-text text-uppercase"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "test will be available soon"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                  ]
                )
              }),
              0
            )
          ],
          1
        )
      ]),
      _vm.isTest
        ? _c("TestModal", {
            attrs: {
              "test-data": _vm.testData,
              tests: _vm.items,
              index: _vm.index
            },
            on: { closeTest: _vm.closeTest, finishTest: _vm.onFinishTest }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
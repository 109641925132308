var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "information" },
    [
      _c(
        "v-container",
        { staticClass: "information__container" },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("div", { staticClass: "information__text" }, [
                  _vm._v(_vm._s(_vm.$t("information_text")))
                ])
              ]),
              _c("v-col", { attrs: { cols: "7" } }, [
                _c(
                  "div",
                  { staticClass: "information__slogan information__slogan--1" },
                  [_vm._v(_vm._s(_vm.$t("information_slogan_1")))]
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "div",
                  {
                    staticClass:
                      "information__slogan information__slogan--second"
                  },
                  [_vm._v(_vm._s(_vm.$t("information_slogan_2")))]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
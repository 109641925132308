var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "section-mp-speakers" }, [
    _c(
      "div",
      { staticClass: "mp-speakers-container" },
      [
        _c("v-container", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "mp-speakers-top-block" }, [
                _c(
                  "div",
                  { staticClass: "mp-speakers-top-left" },
                  [
                    _c("div", { staticClass: "sect-count" }, [_vm._v(" .04 ")]),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: { name: "schedule", hash: "#shcedule_speakers" }
                        }
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass: "section-title",
                            staticStyle: { "max-width": "340px" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("upperFirst")(_vm.$t("forum speakers"))
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "mp-speakers-top-right" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mp-speakers-all-link-block d-none d-md-block"
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "mp-speaker-all-link",
                          attrs: {
                            to: { name: "schedule", hash: "#shcedule_speakers" }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("upperFirst")(_vm.$t("all speakers"))
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ]),
        _c("v-container", { staticClass: "speaker-slider-block-container" }, [
          _c(
            "div",
            { staticClass: "speaker-slider-block" },
            [
              _c(
                "Slick",
                {
                  ref: "carousel",
                  staticClass: "speaker-slider slider",
                  attrs: { options: _vm.sliderSettings }
                },
                _vm._l(_vm.items, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: "" + index + item.name,
                      staticClass: "speaker-slide slide"
                    },
                    [
                      _c("div", { staticClass: "speaker-item" }, [
                        _c(
                          "div",
                          { staticClass: "speaker-item__img-block" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "speaker-item__img",
                                attrs: { to: "/profile-speaker/" + item.slug }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.getLinkByFileName(
                                      "speakers",
                                      "large",
                                      item.photo
                                    ),
                                    alt: item.name
                                  },
                                  on: { error: _vm.handleError }
                                })
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "speaker-item__soc-links" },
                              [
                                item.instagram
                                  ? _c("a", {
                                      staticClass:
                                        "speaker-item__soc-link speaker-item__soc-link--instagram",
                                      attrs: {
                                        href: item.instagram,
                                        target: "_blank"
                                      }
                                    })
                                  : _vm._e(),
                                item.facebook
                                  ? _c("a", {
                                      staticClass:
                                        "speaker-item__soc-link speaker-item__soc-link--twitter",
                                      attrs: {
                                        href: item.facebook,
                                        target: "_blank"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "speaker-item__content" }, [
                          _c(
                            "div",
                            { staticClass: "speaker-item__name" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: { to: "/profile-speaker/" + item.slug }
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "speaker-item__status" }, [
                            _vm._v(" " + _vm._s(item.position) + " ")
                          ])
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        ]),
        _c("v-container", [
          _c(
            "div",
            { staticClass: "mp-speakers-all-link-block-mobile-wrapper" },
            [
              _c(
                "div",
                { staticClass: "mp-speakers-all-link-block" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "mp-speaker-all-link",
                      attrs: {
                        to: { name: "schedule", hash: "#shcedule_speakers" }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("upperFirst")(_vm.$t("all speakers"))) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
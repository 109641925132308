import axios from 'axios'

const GOOGLE_TRANSLATE_API_URL = 'https://translation.googleapis.com/language/translate/v2'

export default async function translate (text, target) {
  const response = await axios.get(GOOGLE_TRANSLATE_API_URL, {
    params: {
      key: process.env.VUE_APP_GOOGLE_CLOUD_API_KEY,
      target,
      q: text
    }
  })
  return response.data.data.translations[0].translatedText
}

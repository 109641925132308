var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section-mp-rank" },
    [
      _vm._m(0),
      _vm.isForum22
        ? [
            _c(
              "div",
              { staticClass: "section-mp-rank__container forum-rank-title" },
              [
                _c("v-container", [
                  _c("div", { staticClass: "focus-possibility" }, [
                    _vm._v(" FOCUS "),
                    _c("br"),
                    _vm._v("ON DISCOVERIES ")
                  ])
                ])
              ],
              1
            )
          ]
        : _vm._e(),
      _c("v-container", [
        _c("div", { staticClass: "mp-rank-top-block" }, [
          _c(
            "div",
            { staticClass: "mp-rank-top-left" },
            [
              _c("div", { staticClass: "sect-count" }, [_vm._v(" .05 ")]),
              _c("router-link", { attrs: { to: "/nominations" } }, [
                _c(
                  "h2",
                  {
                    staticClass: "section-title",
                    staticStyle: { "max-width": "340px" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("upperFirst")(_vm.$t("new ranks"))) +
                        " "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c("div", { staticClass: "mp-rank-top-right" }, [
            _c("div", { staticClass: "mp-rank-period" }, [
              _vm._v(" " + _vm._s(_vm.$t("from june 20' till jan 21'")) + " ")
            ])
          ])
        ])
      ]),
      _c(
        "v-lazy",
        {
          attrs: {
            options: {
              threshold: 0.3
            },
            transition: "fade-transition"
          }
        },
        [
          _c("v-container", [
            _c(
              "div",
              { staticClass: "mp-rank-items-block" },
              [
                _vm.isForum22
                  ? _c(
                      "Slick",
                      {
                        ref: "carousel",
                        attrs: { options: _vm.sliderSettings }
                      },
                      [
                        _vm._l(_vm.ranksList, function(ranks, key) {
                          return [
                            _vm._l(ranks, function(rank, rankKey) {
                              return [
                                rank.type === "rank"
                                  ? _c(
                                      "div",
                                      { key: key + "key" + rankKey },
                                      [
                                        _c(
                                          "router-link",
                                          { attrs: { to: rank.linkUrl } },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "forum-rank" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "forum-rank__icon-container"
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "forum-rank__icon"
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "forum-rank__icon-text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                _vm.crownText(
                                                                  rank.rank
                                                                )
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "forum-rank__rank"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(rank.count) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "forum-rank__title"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("upperFirst")(
                                                            _vm.$tc(
                                                              rank.title,
                                                              rank.count
                                                            )
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            })
                          ]
                        })
                      ],
                      2
                    )
                  : _c(
                      "div",
                      { ref: "mprank", class: { isMobile: _vm.isMobile } },
                      [
                        _vm._l(_vm.ranksList, function(ranks, key) {
                          return [
                            _c(
                              "div",
                              { key: key, staticClass: "mp-rank-items-row" },
                              [
                                _vm._l(ranks, function(rank, rankKey) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        key: rankKey,
                                        staticClass: "mp-rank-item"
                                      },
                                      [
                                        rank.type === "rank"
                                          ? _c(
                                              "router-link",
                                              { attrs: { to: rank.linkUrl } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    class: [
                                                      "mp-rank-item__content",
                                                      rank.color
                                                    ]
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mp-rank-item__count"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(rank.count) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mp-rank-item__text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "upperFirst"
                                                              )(
                                                                _vm.$tc(
                                                                  rank.title,
                                                                  rank.count
                                                                )
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : rank.type === "image"
                                          ? _c(
                                              "router-link",
                                              { attrs: { to: rank.linkUrl } },
                                              [
                                                _c("c-img", {
                                                  staticClass:
                                                    "mp-rank-item__img",
                                                  attrs: {
                                                    src: rank.imageUrl,
                                                    alt: ""
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _c("router-link", {
                                              staticClass: "mp-rank-item",
                                              attrs: { to: rank.linkUrl }
                                            })
                                      ],
                                      1
                                    )
                                  ]
                                })
                              ],
                              2
                            )
                          ]
                        })
                      ],
                      2
                    )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "mp-rank-all-link-block-mobile-wrapper" }, [
        _c(
          "div",
          { staticClass: "mp-rank-all-link-block" },
          [
            _c(
              "router-link",
              {
                staticClass: "mp-rank-all-link",
                attrs: { to: "/nominations" }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("upperFirst")(_vm.$t("all nominees"))) +
                    " "
                )
              ]
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-mp-rank__text container" }, [
      _c("span", [_vm._v(" Ocean "), _c("br"), _vm._v(" of discoveries ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
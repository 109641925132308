var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "expert-chat-wrapper" }, [
    _c("div", { staticClass: "expert-chat-header" }, [
      _c(
        "div",
        { staticClass: "expert-chat-tabs" },
        _vm._l(_vm.chatTabs, function(tab, index) {
          return _c(
            "div",
            {
              key: index,
              class: [
                "expert-chat-tab",
                { "expert-chat-tab--active": _vm.activeChatTabIndex === index }
              ],
              on: {
                click: function($event) {
                  _vm.activeChatTabIndex = index
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t(tab.label)) + " ")]
          )
        }),
        0
      )
    ]),
    _vm.activeChatTabIndex === 0
      ? _c(
          "div",
          { staticClass: "expert-chat" },
          [
            _c(
              "div",
              { staticClass: "expert-chat__events" },
              [
                _c("ChatEventsList", {
                  ref: "eventsList",
                  attrs: { items: _vm.messages },
                  on: {
                    vote: _vm.toVote,
                    resend: _vm.resendMessage,
                    contextmenu: _vm.onContextMenu
                  }
                }),
                _c(
                  "div",
                  { staticClass: "expert-chat__event-editor" },
                  [
                    _c("v-text-field", {
                      staticClass: "expert-chat__message-input",
                      attrs: {
                        rows: "1",
                        placeholder: _vm.$t("leave comment expert"),
                        "no-resize": "",
                        outlined: "",
                        rounded: "",
                        "hide-details": "",
                        "append-icon": "mdi-arrow-right"
                      },
                      on: {
                        keypress: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          if (
                            $event.ctrlKey ||
                            $event.shiftKey ||
                            $event.altKey ||
                            $event.metaKey
                          ) {
                            return null
                          }
                          $event.preventDefault()
                          return _vm.onSendMessage($event)
                        },
                        "click:append": _vm.onSendMessage
                      },
                      model: {
                        value: _vm.message,
                        callback: function($$v) {
                          _vm.message = $$v
                        },
                        expression: "message"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { width: "500" },
                model: {
                  value: _vm.formDialog,
                  callback: function($$v) {
                    _vm.formDialog = $$v
                  },
                  expression: "formDialog"
                }
              },
              [
                _vm.beingEdited && _vm.beingEdited.type === "text"
                  ? _c("TextForm", {
                      attrs: {
                        value: _vm.beingEdited,
                        loading: _vm.isUpdateMessageLoading
                      },
                      on: {
                        cancel: function($event) {
                          _vm.formDialog = false
                        },
                        submit: _vm.onTextSubmit
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "v-menu",
              {
                attrs: { "position-x": _vm.menu.x, "position-y": _vm.menu.y },
                model: {
                  value: _vm.menu.showMenu,
                  callback: function($$v) {
                    _vm.$set(_vm.menu, "showMenu", $$v)
                  },
                  expression: "menu.showMenu"
                }
              },
              [
                _c(
                  "v-list",
                  [
                    _c(
                      "v-list-item",
                      {
                        attrs: { disabled: _vm.disableEditChatEvent },
                        on: { click: _vm.startEditEvent }
                      },
                      [
                        _c("v-list-item-title", [
                          _vm._v(_vm._s(_vm.$t("edit")))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        attrs: { disabled: _vm.disableEditChatEvent },
                        on: {
                          click: function($event) {
                            _vm.confirmDeleteDialog = true
                          }
                        }
                      },
                      [
                        _c("v-list-item-title", [
                          _vm._v(_vm._s(_vm.$t("delete")))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("ConfirmDelete", {
              attrs: { loading: _vm.isDeleteMessageLoading },
              on: {
                cancel: function($event) {
                  _vm.confirmDeleteDialog = false
                },
                confirm: _vm.removeEvent
              },
              model: {
                value: _vm.confirmDeleteDialog,
                callback: function($$v) {
                  _vm.confirmDeleteDialog = $$v
                },
                expression: "confirmDeleteDialog"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.activeChatTabIndex === 1
      ? _c(
          "div",
          { staticClass: "expert-chat" },
          [
            _c(
              "div",
              { staticClass: "expert-chat__events" },
              [
                _c("ChatEventsList", {
                  ref: "eventsList",
                  attrs: {
                    items: _vm.sharedMessages,
                    "enable-translate-messages": true
                  },
                  on: {
                    vote: _vm.toVote,
                    resend: _vm.resendMessage,
                    contextmenu: _vm.onContextMenu
                  }
                }),
                _c(
                  "div",
                  { staticClass: "expert-chat__event-editor" },
                  [
                    _c("v-text-field", {
                      staticClass: "expert-chat__message-input",
                      attrs: {
                        rows: "1",
                        placeholder: _vm.$t("leave comment"),
                        "no-resize": "",
                        outlined: "",
                        rounded: "",
                        "hide-details": "",
                        readonly: _vm.disableCommonChat,
                        "append-icon": "mdi-arrow-right"
                      },
                      on: {
                        keypress: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          if (
                            $event.ctrlKey ||
                            $event.shiftKey ||
                            $event.altKey ||
                            $event.metaKey
                          ) {
                            return null
                          }
                          $event.preventDefault()
                          return _vm.onSendMessage($event)
                        },
                        "click:append": _vm.onSendMessage
                      },
                      model: {
                        value: _vm.message,
                        callback: function($$v) {
                          _vm.message = $$v
                        },
                        expression: "message"
                      }
                    }),
                    _vm.disableCommonChat
                      ? _c(
                          "div",
                          {
                            class: [
                              "expert-chat__action-not-available",
                              {
                                "expert-chat__action-not-available--visible":
                                  _vm.notificationVisible
                              }
                            ]
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("upperFirst")(
                                    _vm.$t("chat action not available")
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { width: "500" },
                model: {
                  value: _vm.formDialog,
                  callback: function($$v) {
                    _vm.formDialog = $$v
                  },
                  expression: "formDialog"
                }
              },
              [
                _vm.beingEdited && _vm.beingEdited.type === "text"
                  ? _c("TextForm", {
                      attrs: {
                        value: _vm.beingEdited,
                        loading: _vm.isUpdateMessageLoading
                      },
                      on: {
                        cancel: function($event) {
                          _vm.formDialog = false
                        },
                        submit: _vm.onTextSubmit
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "v-menu",
              {
                attrs: { "position-x": _vm.menu.x, "position-y": _vm.menu.y },
                model: {
                  value: _vm.menu.showMenu,
                  callback: function($$v) {
                    _vm.$set(_vm.menu, "showMenu", $$v)
                  },
                  expression: "menu.showMenu"
                }
              },
              [
                _c(
                  "v-list",
                  [
                    _c(
                      "v-list-item",
                      {
                        attrs: { disabled: _vm.disableEditChatEvent },
                        on: { click: _vm.startEditEvent }
                      },
                      [
                        _c("v-list-item-title", [
                          _vm._v(_vm._s(_vm.$t("edit")))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        attrs: { disabled: _vm.disableEditChatEvent },
                        on: {
                          click: function($event) {
                            _vm.confirmDeleteDialog = true
                          }
                        }
                      },
                      [
                        _c("v-list-item-title", [
                          _vm._v(_vm._s(_vm.$t("delete")))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("ConfirmDelete", {
              attrs: { loading: _vm.isDeleteMessageLoading },
              on: {
                cancel: function($event) {
                  _vm.confirmDeleteDialog = false
                },
                confirm: _vm.removeEvent
              },
              model: {
                value: _vm.confirmDeleteDialog,
                callback: function($$v) {
                  _vm.confirmDeleteDialog = $$v
                },
                expression: "confirmDeleteDialog"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
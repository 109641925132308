var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "section-mp-schedule",
      attrs: { id: "section-mp-schedule" }
    },
    [
      _c("v-container", [
        _c("div", { staticClass: "section-mp-schedule__bg-text" }),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-xxl-4" },
            [
              _c("div", { staticClass: "sect-count" }, [_vm._v(" .03 ")]),
              _c("router-link", { attrs: { to: "/schedule" } }, [
                _c(
                  "h2",
                  {
                    staticClass: "section-title",
                    staticStyle: { "max-width": "340px" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("upperFirst")(_vm.$t("forum schedule"))) +
                        " "
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "section-descr" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("upperFirst")(
                        _vm.$t(
                          "the broadcast schedule is indicated by moscow time (gmt +3)"
                        )
                      )
                    ) +
                    " "
                )
              ])
            ],
            1
          ),
          _c("div", { staticClass: "col-12 col-xxl-8" }, [
            _c("div", { staticClass: "mp-schedule-block" }, [
              _c("div", { staticClass: "mp-schedule-top" }, [
                _c("div", { staticClass: "mp-schedule-top-left" }, [
                  _c(
                    "div",
                    { staticClass: "mp-schedule-tabs" },
                    _vm._l(_vm.eventDays, function(day, index) {
                      return _c(
                        "router-link",
                        {
                          key: day.id,
                          staticClass: "mp-schedule-tab",
                          class: {
                            active: _vm.$route.path === "/page/day/" + day.index
                          },
                          attrs: {
                            tag: "div",
                            to: {
                              path: "/page/day/" + day.index,
                              hash: "#section-mp-schedule"
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "mp-schedule-tab__inner" }, [
                            _c(
                              "div",
                              { staticClass: "mp-schedule-tab__text" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("upperFirst")(_vm.$t("day"))
                                    ) +
                                    " " +
                                    _vm._s(day.index) +
                                    " "
                                )
                              ]
                            ),
                            day.isDayStart
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "mp-schedule-tab__live",
                                    class: {
                                      "mp-schedule-tab__live--left":
                                        index === 0,
                                      "mp-schedule-tab__live--middle":
                                        index > 0 &&
                                        index < _vm.eventDays.length - 1
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/svg/live.svg"),
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "mp-schedule-tabs_content" }, [
                _c("div", { staticClass: "mp-schedule-tab_content" }, [
                  _c(
                    "div",
                    { staticClass: "mp-schedule-items" },
                    [
                      _c("ListSchedule", {
                        attrs: {
                          items: _vm.routeDayItems,
                          "show-description": true
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "mp-schedule-tab_content" })
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
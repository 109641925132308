<template>
  <section class="section-mp-knowledge" style="position: relative !important;">
    <v-container>
      <div class="row">
        <div class="col-12">
          <div class="mp-knowledge-top-block">
            <div class="sect-count">
              .02
            </div>

            <h2 style="max-width: 340px;">
              {{ $t('test your knowledge') | upperFirst }}
            </h2>

            <div class="mp-knowledge-top-bg-text">
              <!-- Элемент дизайна, не требует перевода -->
              TEST<span class="d-none d-lg-inline">S & QUIZES</span>
            </div>
          </div>
        </div>
      </div>
    </v-container>

    <v-container class="knowledge-slider-block-container">
      <div class="knowledge-slider-block">
        <Slick
          ref="carousel"
          :options="sliderSettings"
          class="knowledge-slider slider"
        >
          <div
            v-for="(item, index) in items"
            :key="'' + index + item.name"
            class="knowledge-slide slide"
          >
            <div v-if="!item.status && item.isActive" class="knowledge-s-item">
              <div
                class="knowledge-s-item__inner"
                :style="{backgroundImage: `url(${odd(index) ?
                  getLinkByEvent(knowledgeSItemBg1)
                  : getLinkByEvent(knowledgeSItemBg2)}),
                  url(${odd(index) ?
                  knowledgeSItemBgLocal1
                : knowledgeSItemBgLocal2})
                  `}"
              >
                <div class="knowledge-s-item__top">
                  <div class="knowledge-s-item__count">
                    .0{{ index + 1 }}
                  </div>
                </div>

                <div class="knowledge-s-item__content">
                  <h3>
                    {{ item.name }}
                  </h3>

                  <div class="knowledge-s-item__text">
                    {{ item.description }}
                  </div>
                </div>

                <div class="knowledge-s-item__bottom">
                  <div v-if="item.status" class="knowledge-s-item__complete-text text-uppercase">
                    {{ $t('test passed') }}
                  </div>

                  <a
                    v-else
                    class="knowledge-s-item__btn white-btn"
                    @click="takeTest(item, index)"
                  >
                    {{ $t('take the test') | upperFirst }}
                  </a>
                </div>
              </div>
            </div>

            <div v-else class="knowledge-s-item knowledge-s-item--complete">
              <div class="knowledge-s-item__inner">
                <div class="knowledge-s-item__top">
                  <div class="knowledge-s-item__count">
                    .0{{ index + 1 }}
                  </div>
                </div>

                <div class="knowledge-s-item__content">
                  <h3>
                    {{ item.name }}
                  </h3>

                  <div class="knowledge-s-item__text">
                    {{ item.description }}
                  </div>
                </div>

                <div class="knowledge-s-item__bottom">
                  <div v-if="item.status" class="knowledge-s-item__complete-text text-uppercase">
                    {{ $t('test passed') }}
                  </div>
                  <div v-else class="knowledge-s-item__complete-text text-uppercase">
                    {{ $t('test will be available soon') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slick>
      </div>
    </v-container>

    <TestModal
      v-if="isTest"
      :test-data="testData"
      :tests="items"
      :index="index"
      @closeTest="closeTest"
      @finishTest="onFinishTest"
    />
  </section>
</template>

<script>
import Slick from 'vue-slick'
import TestModal from '@/components/Tests'
import { getLinkByEvent } from '@/utils/mediaHelpers'
import { getThemePath } from '@/utils/common'

const imagePath = `assets/img/${getThemePath()}`

export default {
  components: { Slick, TestModal },

  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },

  data: () => ({
    knowledgeSItemBg1: `${imagePath}/knowledge-s-item-bg-1.png`,
    knowledgeSItemBg2: `${imagePath}/knowledge-s-item-bg-2.png`,
    knowledgeSItemBgLocal1: require(`@/${imagePath}/knowledge-s-item-bg-1.png`),
    knowledgeSItemBgLocal2: require(`@/${imagePath}/knowledge-s-item-bg-2.png`),
    sliderSettings: {
      slidesToShow: 3,
      arrows: true,
      dots: false,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            arrows: true,
            slidesToShow: 2
          }
        },
        {
          breakpoint: 992,
          settings: {
            arrows: true,
            slidesToShow: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            variableWidth: true,
            centerPadding: '0px',
            slidesToShow: 1
          }
        }
      ]
    },
    isTest: false,
    testData: null
  }),

  watch: {
    items () {
      const currIndex = this.$refs.carousel.currentSlide()

      this.$refs.carousel.destroy()
      this.$nextTick(() => {
        this.$refs.carousel.create()
        this.$refs.carousel.goTo(currIndex, true)
      })
    }
  },
  methods: {
    getLinkByEvent,
    odd (val) {
      return val % 2 === 0
    },

    takeTest (val, index) {
      this.isTest = true
      this.testData = val
      this.index = index
    },

    onFinishTest ({ status, test }) {
      this.$emit('finishTest', { status, test })
      this.closeTest()
    },

    closeTest () {
      this.isTest = false
    }
  }
}
</script>

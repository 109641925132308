



































import Vue, { PropType } from 'vue'
import { requiredRule } from '@/utils/validate'
import { ChatTextMessage } from '@/modules/ExpertChat/types'
import { cloneDeep } from 'lodash'

export default Vue.extend({
  props: {
    value: {
      type: Object as PropType<ChatTextMessage>,
      required: true,
      default: () => ({} as ChatTextMessage)
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    localValue: {} as ChatTextMessage
  }),

  created () {
    if (this.value) this.localValue = cloneDeep(this.value)
  },

  methods: {
    onSubmit () {
      const form = this.$refs.form as (Vue & { validate: () => boolean})
      if (!form.validate()) return
      this.$emit('submit', this.localValue)
    },

    requiredRule
  }
})

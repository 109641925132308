var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-main-wrapper" }, [
    _c("div", { staticClass: "page page--main" }, [
      _c(
        "div",
        { staticClass: "page-inner" },
        [
          _c("Hero", {
            attrs: {
              "current-event": _vm.currentEventStaticData,
              user: _vm.user,
              day: _vm.liveDay
            }
          }),
          _c("Broadcast", { attrs: { presentation: _vm.presentation } }),
          _vm.activeEvent ? [_c("Information")] : _vm._e(),
          _vm.settings && _vm.settings.test.enabled
            ? _c("Knowledge", {
                attrs: { items: _vm.tests },
                on: { finishTest: _vm.onFinishTest }
              })
            : _vm._e(),
          _vm.banner1
            ? [
                _c("Banner", {
                  staticClass: "page__banner1",
                  attrs: { data: _vm.banner1, "image-position": "right" }
                })
              ]
            : _vm._e(),
          _vm.isForum22
            ? [
                _c(
                  "div",
                  { staticClass: "focus-possibility__container" },
                  [
                    _c("v-container", [
                      _c("div", { staticClass: "focus-possibility" }, [
                        _vm._v(" FOCUS "),
                        _c("br"),
                        _vm._v("ON POSSIBILITIES ")
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "focus-possibility__container--mobile" },
                  [
                    _c("v-container", [
                      _c("div", { staticClass: "focus-possibility" }, [
                        _vm._v(" OCEAN OF "),
                        _c("br"),
                        _vm._v("POSSIBILITIES ")
                      ])
                    ])
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.settings && _vm.settings.schedule && _vm.settings.schedule.enabled
            ? _c("Schedule", { attrs: { items: _vm.schedules } })
            : _vm._e(),
          _vm.settings && _vm.settings.speakers.enabled
            ? _c("Speakers", { attrs: { items: _vm.speakers } })
            : _vm._e(),
          _vm.banner2
            ? [
                _c("Banner", {
                  staticClass: "page__banner2",
                  attrs: { data: _vm.banner2 }
                })
              ]
            : _vm._e(),
          _vm.settings &&
          _vm.settings.nominations &&
          _vm.settings.nominations.enabled
            ? _c("NewRanks", {
                attrs: {
                  items: _vm.ranks,
                  "is-forum22": _vm.isForum22,
                  "current-event": _vm.currentEventStaticData
                }
              })
            : _vm._e(),
          _vm.settings && _vm.settings.materials.enabled
            ? _c("UsefulMaterial", { attrs: { items: _vm.materials } })
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: { width: "400" },
          model: {
            value: _vm.valueInput,
            callback: function($$v) {
              _vm.valueInput = $$v
            },
            expression: "valueInput"
          }
        },
        "v-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline text-capitalize" }, [
            _vm._v(" " + _vm._s(_vm.$t("delete")) + " ? ")
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "green darken-1",
                    text: "",
                    disabled: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("no")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "green darken-1",
                    text: "",
                    loading: _vm.loading,
                    disabled: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("confirm")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("yes")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section id="section-mp-schedule" class="section-mp-schedule">
    <v-container>
      <!-- eslint-disable-next-line vue/html-self-closing -->
      <div class="section-mp-schedule__bg-text">
      </div>
      <div class="row">
        <div class="col-12 col-xxl-4">
          <div class="sect-count">
            .03
          </div>

          <router-link
            to="/schedule"
          >
            <h2
              style="max-width: 340px;"
              class="section-title"
            >
              {{ $t('forum schedule') | upperFirst }}
            </h2>
          </router-link>

          <div class="section-descr">
            {{ $t('the broadcast schedule is indicated by moscow time (gmt +3)') | upperFirst }}
          </div>
        </div>

        <div class="col-12 col-xxl-8">
          <div class="mp-schedule-block">
            <div class="mp-schedule-top">
              <div class="mp-schedule-top-left">
                <div class="mp-schedule-tabs">
                  <router-link
                    v-for="(day, index) in eventDays"
                    :key="day.id"
                    tag="div"
                    :to="{path: `/page/day/${day.index}`, hash: '#section-mp-schedule'}"
                    class="mp-schedule-tab"
                    :class="{active: $route.path === `/page/day/${day.index}`}"
                  >
                    <div class="mp-schedule-tab__inner">
                      <div class="mp-schedule-tab__text">
                        {{ $t('day') | upperFirst }} {{ day.index }}
                      </div>

                      <div
                        v-if="day.isDayStart"
                        class="mp-schedule-tab__live"
                        :class="{
                          'mp-schedule-tab__live--left': index === 0,
                          'mp-schedule-tab__live--middle': index > 0 && index < eventDays.length - 1
                        }"
                      >
                        <img
                          :src="require('@/assets/img/svg/live.svg')"
                          alt=""
                        >
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="mp-schedule-tabs_content">
              <div class="mp-schedule-tab_content">
                <div class="mp-schedule-items">
                  <ListSchedule :items="routeDayItems" :show-description="true" />
                </div>
              </div>
              <div class="mp-schedule-tab_content" />
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import { getLinkByFileName } from '@/utils/mediaHelpers'
import businessEvent from '@/services/businessEvent'
import ListSchedule from '@/components/ListSchedule'
import EventBus from '@/EventBus'

export default {

  components: {
    ListSchedule
  },
  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },

  data: () => ({
    eventDays: [],
    hoverId: null
  }),

  computed: {
    routeDayItems () {
      return this.items[this.$route.params.day - 1] || []
    }
  },

  async created () {
    this.eventDays = await businessEvent.getEventDays()

    EventBus.subscribe('businessEvent:update', this.onEventUpdate)
  },

  beforeDestroy () {
    EventBus.unsubscribe('businessEvent:update', this.onEventUpdate)
  },

  methods: {
    getLinkByFileName,
    handleError (e) {
      e.target.src = getLinkByFileName('/assets/img/theme/person-plug-mini.png')
    },
    setHover (i) {
      this.hoverId = i
    },
    async onEventUpdate () {
      this.eventDays = await businessEvent.getEventDays()
    }
  }
}
</script>

<template>
  <header class="header-page-main">
    <div class="header-container">
      <div v-if="currentEvent.name === THEME_FORUM_22" class="header-inner">
        <div class="header-content">
          <img
            class="header-content__hero"
            :src="require('@/assets/img/theme-forum-22/hero-image.png')"
            alt=""
          >
        </div>
      </div>
      <v-container v-else>
        <div class="row">
          <div class="col-12">
            <div class="header-inner">
              <div class="header-content">
                <div class="hc-logo-block">
                  <div class="hc-logo">
                    <img :src="require('@/assets/img/svg/forum_logo.svg')" alt="">
                  </div>
                </div>

                <div class="hc-tr-text-block">
                  <div class="hc-tr-text">
                    <!-- Элемент дизайна, не требует перевода-->
                    <span>NOW LIVE </span>
                    <span class="d-none d-lg-inline">DAY {{ day }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </header>
</template>

<script>
import { THEME_FORUM_22 } from '@/constants/events'
export default {
  props: {
    day: {
      type: [Number, String],
      default: 1
    },
    currentEvent: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    THEME_FORUM_22
  })
}
</script>

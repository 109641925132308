<template>
  <section class="information">
    <v-container class="information__container">
      <v-row>
        <v-col>
          <!-- eslint-disable-next-line -->
          <div class="information__text">{{ $t(`information_text`) }}</div>
        </v-col>
        <v-col cols="7">
          <!-- eslint-disable-next-line -->
          <div class="information__slogan information__slogan--1">{{ $t(`information_slogan_1`) }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- eslint-disable-next-line -->
          <div class="information__slogan information__slogan--second">{{ $t(`information_slogan_2`) }}</div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'Information'
}
</script>

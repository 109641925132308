var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "header-page-main" }, [
    _c(
      "div",
      { staticClass: "header-container" },
      [
        _vm.currentEvent.name === _vm.THEME_FORUM_22
          ? _c("div", { staticClass: "header-inner" }, [
              _c("div", { staticClass: "header-content" }, [
                _c("img", {
                  staticClass: "header-content__hero",
                  attrs: {
                    src: require("@/assets/img/theme-forum-22/hero-image.png"),
                    alt: ""
                  }
                })
              ])
            ])
          : _c("v-container", [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "header-inner" }, [
                    _c("div", { staticClass: "header-content" }, [
                      _c("div", { staticClass: "hc-logo-block" }, [
                        _c("div", { staticClass: "hc-logo" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/svg/forum_logo.svg"),
                              alt: ""
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "hc-tr-text-block" }, [
                        _c("div", { staticClass: "hc-tr-text" }, [
                          _c("span", [_vm._v("NOW LIVE ")]),
                          _c("span", { staticClass: "d-none d-lg-inline" }, [
                            _vm._v("DAY " + _vm._s(_vm.day))
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
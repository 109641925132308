<template>
  <div class="presentation">
    <img
      :src="presentation.slide"
      alt=""
      @error="ifNotImage"
    >
  </div>
</template>

<script>
import { getLinkByEvent } from '@/utils/mediaHelpers'
import { mapState } from 'vuex'
import { getThemePath } from '@/utils/common'

export default {
  computed: {
    ...mapState(['presentation'])
  },

  methods: {
    getLinkByEvent,
    ifNotImage (e) {
      e.target.src = getLinkByEvent(`/assets/img/${getThemePath()}/presentation-plug.jpg`)
    }
  }
}
</script>

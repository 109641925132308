var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "finish" }, [
    _c("h3", { staticClass: "finish__title" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$t("your result")) +
          " - " +
          _vm._s(_vm.countRightAnswer) +
          " / " +
          _vm._s(_vm.answers.length) +
          " "
      )
    ]),
    _vm.status === "success"
      ? _c("p", { staticClass: "finish__desc" }, [
          _vm._v(" " + _vm._s(_vm.$t("test success")) + " ")
        ])
      : _c("p", { staticClass: "finish__desc" }, [
          _vm._v(" " + _vm._s(_vm.$t("test fail")) + " ")
        ]),
    _c("div", { staticClass: "next-btn", on: { click: _vm.finishTest } }, [
      _vm._v(" " + _vm._s(_vm.$t("finish test")) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import apiClient from './apiClient'
import { TESTS_PREFIX } from '@/constants/apiPrefixes'
import businessService from '@/services/businessEvent'
import languageService from '@/services/language'

const getEventId = () => businessService.getActiveEvent().then(res => res.id)

const getLanguage = () => languageService.getLanguage().then(res => res.id)

export const getTests = async () =>
  apiClient.get(`${TESTS_PREFIX}/${await getEventId()}/quizzes/${await getLanguage()}`)

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-inner" }, [
      _c("div", { staticClass: "modal-wrap" }, [
        _c("div", { staticClass: "modal modal--test" }, [
          _c(
            "div",
            {
              staticClass: "modal-close",
              on: {
                click: function($event) {
                  return _vm.$emit("closeTest")
                }
              }
            },
            [
              _c(
                "v-icon",
                { attrs: { large: "", dark: _vm.windowWidth < 600 } },
                [_vm._v(" mdi-close ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-header" },
            [
              _c("c-img", {
                staticClass: "modal__top-element",
                attrs: {
                  src: "assets/img/theme/modal-top-element.png",
                  alt: ""
                }
              }),
              _c("c-img", {
                staticClass: "modal__bottom-element",
                attrs: {
                  src: "assets/img/theme/modal-bottom-element.png",
                  alt: ""
                }
              }),
              _c("div", [
                _vm.block !== "test" && _vm.windowWidth > 600
                  ? _c("p", { staticClass: "header-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("upperFirst")(_vm.$t("test your knowledge"))
                          ) +
                          " "
                      )
                    ])
                  : _vm._e(),
                _vm.status === "wait"
                  ? _c("div", [
                      _vm.block === "test" && _vm.windowWidth < 600
                        ? _c("div", [
                            _c("p", { staticClass: "header-title__small" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("upperFirst")(_vm.$t("test"))) +
                                  ": "
                              )
                            ]),
                            _c("p", { staticClass: "header-title__small" }, [
                              _vm._v(" " + _vm._s(_vm.testData.name) + " ")
                            ])
                          ])
                        : _c("h2", { staticClass: "header-desc" }, [
                            _vm._v(" " + _vm._s(_vm.testData.name) + " ")
                          ])
                    ])
                  : _vm._e(),
                _vm.status === "success"
                  ? _c("div", [
                      _c("h2", { staticClass: "header-desc" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("upperFirst")(_vm.$t("congratulations"))
                            ) +
                            " "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.status === "fail"
                  ? _c("div", [
                      _c("h2", { staticClass: "header-desc" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("upperFirst")(_vm.$t("test failed"))
                            ) +
                            " "
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "test-modal__logo" },
                [
                  _c("c-img", {
                    staticClass: "header-logo",
                    attrs: {
                      src:
                        "assets/img/" + _vm.themePath + "/event-logo-light.png",
                      alt: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-wrapper" },
            [
              _vm.block === "start"
                ? _c("Start", {
                    attrs: { test: _vm.testData },
                    on: { startTest: _vm.startTest }
                  })
                : _vm.block === "test"
                ? _c("Question", {
                    attrs: { questions: _vm.testData.questions },
                    on: { finishTest: _vm.finishTest }
                  })
                : _vm.block === "finish"
                ? _c("Finish", {
                    attrs: {
                      "count-right-answer": _vm.countRightAnswer,
                      status: _vm.status,
                      answers: _vm.answers
                    },
                    on: { finishTest: _vm.saveTestStatus }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
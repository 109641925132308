var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      _vm._b(
        {
          class: [
            "expert-chat-event-text",
            {
              "expert-chat-event-text--translated": _vm.translatedText
            }
          ]
        },
        "div",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "expert-chat-event-text__info" }, [
        _c(
          "div",
          {
            staticClass: "expert-chat-event-text__name text-capitalize",
            class: _vm.myMessage
          },
          [_vm._v(" " + _vm._s(_vm.value.user.fullName) + " ")]
        ),
        _c("div", { staticClass: "expert-chat-event-text__time" }, [
          _vm._v(" " + _vm._s(_vm._f("dateTime")(_vm.value.createdAt)) + " ")
        ])
      ]),
      _c("div", { staticClass: "expert-chat-event-text__content" }, [
        _c("pre", {
          domProps: { innerHTML: _vm._s(_vm.sanitizeHtml(_vm.isLink)) }
        })
      ]),
      _vm.enableTranslate
        ? _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple",
                      value: false,
                      expression: "false"
                    }
                  ],
                  staticClass: "translate",
                  attrs: { loading: _vm.translateLoading, text: "", plain: "" },
                  on: { click: _vm.translateMessage }
                },
                [
                  _c(
                    "div",
                    { staticClass: "translate__icon" },
                    [
                      _c("c-img", {
                        attrs: { src: "assets/img/svg/translate.svg", alt: "" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "translate__text" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("upperFirst")(_vm.$t("translate"))) +
                        " "
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          class: [
            "expert-chat-event-text__status",
            "expert-chat-event-text__status--" + _vm.value.status
          ]
        },
        [
          _vm.value.status === "pending"
            ? _c("v-progress-circular", {
                attrs: { size: "20", indeterminate: "", color: "primary" }
              })
            : _vm._e(),
          _vm.value.status === "error"
            ? _c(
                "v-icon",
                {
                  attrs: {
                    title: "Возникла ошибка при отправке сообщения. Повторить?"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("resend", _vm.value)
                    }
                  }
                },
                [_vm._v(" mdi-reload-alert ")]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
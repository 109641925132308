import Vuex from 'vuex'
import Vue from 'vue'
import * as contentAPI from '@/services/api/translatesAndContent'
import * as testAPI from '@/services/api/test'
import io from 'socket.io-client'
import businessEventService from '@/services/businessEvent'
import languageService from '@/services/language'
import { getLinkByFileName } from '@/utils/mediaHelpers'
import persistedStorage from 'vuex-persistedstate'
import { cloneDeep } from 'lodash'

const socketDomain = process.env.VUE_APP_BASE_SOCKET_URL
const socketPath = '/api/presentation/wss/'

const socket = io(
  socketDomain,
  {
    path: socketPath,
    transports: ['websocket']
  }
)

const defaultState = () => ({
  materials: [],
  speakers: [],
  schedules: [],
  ranks: [],
  tests: [],
  presentation: {
    isPresentationShown: true,
    slide: null,
    lang: null
  },
  testsStatuses: {}
})

const store = new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',

  state: defaultState(),

  getters: {
    deserializePresentation: () => (data) => ({
      slide: data.image_id && getLinkByFileName('presentations', data.image_id),
      isPresentationShown: data.is_presentation_shown ?? data.is_presentation_show,
      lang: data.lang
    }),

    deserializeTest: (state, getters) => (data) => {
      return {
        id: data.id,
        eventId: data.event_id,
        isActive: data.is_active,
        name: data.name,
        description: data.description,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        deletedAt: data.deleted_at,
        questions: data.questions?.map(getters.deserializeTestQuestion)
      }
    },

    deserializeTestQuestion: (state, getters) => data => ({
      id: data.id,
      quizId: data.quiz_id,
      text: data.text,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      deletedAt: data.deleted_at,
      answerOptions: data.answer_options?.map(getters.deserializeTestAnswer)
    }),

    deserializeTestAnswer: () => data => ({
      id: data.id,
      quizId: data.quiz_id,
      correct: data.correct,
      text: data.text,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      deletedAt: data.deleted_at
    })
  },

  actions: {
    async getContent ({ dispatch }) {
      await Promise.all([
        dispatch('getMaterials'),
        dispatch('getSpeakers'),
        dispatch('getSchedules'),
        dispatch('getTests'),
        dispatch('getRanks')
      ])
    },

    async getRanks ({ commit }) {
      const { data } = await contentAPI.getMainTranslationContent()
      commit('setRanks', data)
    },

    async getMaterials ({ commit }) {
      const { data } = await contentAPI.getMaterials()
      commit('setMaterials', data)
    },

    async getSpeakers ({ commit }) {
      const { data } = await contentAPI.getSpeakers({ onMain: true })
      commit('setSpeakers', data)
    },

    async getSchedules ({ commit }) {
      const { data } = await contentAPI.getSchedules()
      commit('setSchedules', data)
    },

    async getTests ({ commit, getters }) {
      const { data } = await testAPI.getTests()
      commit('setTests', data.map(getters.deserializeTest))
    },

    async subscribeOnPresentationChange ({ commit, getters, state, dispatch }) {
      const selectedEvent = await businessEventService.getActiveEvent()
      const lang = await languageService.getLanguage()

      if (!socket.connected) {
        await new Promise(resolve => socket.once('connect', resolve))
      }

      const res = await new Promise((resolve, reject) => {
        if (state.presentation.lang?.id) {
          socket.off(`subscribe:broadcast:${state.presentation.lang.id}`)
        }

        socket.on(`subscribe:broadcast:${lang.id}`, (...args) => dispatch('onPresentationChange', ...args))

        socket.emit(
          'subscribe',
          {
            body: {
              event_id: selectedEvent.id,
              language_id: lang.id
            }
          },
          ({ success, body, text }) => {
            if (success) {
              return resolve(body)
            }
            return reject(new Error(text))
          }
        )
      })
      commit('setPresentation', getters.deserializePresentation({ ...res, lang }))
    },

    onPresentationChange ({ commit, getters }, payload) {
      commit('setPresentation', getters.deserializePresentation(payload.body))
    },

    updateTestStatus ({ commit }, { status, test }) {
      commit('updateTestStatus', { status, test })
      const updatedTest = { ...cloneDeep(test), status }
      commit('updateTest', updatedTest)
    }
  },

  mutations: {
    setMaterials (state, payload) {
      Vue.set(state, 'materials', Object.values(payload))
    },

    setRanks (state, payload) {
      Vue.set(state, 'ranks', payload)
    },

    setSpeakers (state, payload) {
      Vue.set(state, 'speakers', payload)
    },

    setSchedules (state, payload) {
      const result = payload.map(el => Object.entries(el)
        .map(([id, item]) => ({ ...item, id })))

      Vue.set(state, 'schedules', result)
    },

    setTests (state, payload) {
      state.tests = payload.map(test => ({ ...test, status: state.testsStatuses[test.id] }))
    },

    updateTest (state, test) {
      const testIndex = state.tests.findIndex(el => el.id === test.id)
      state.tests.splice(testIndex, 1, test)
    },

    updateTestStatus (state, { status, test }) {
      Vue.set(state, 'testsStatuses', {
        ...state.testsStatuses,
        [test.id]: status
      })
    },

    setPresentation (state, payload) {
      const newPresentation = payload

      if (!newPresentation.lang) {
        newPresentation.lang = state.presentation.lang
      }

      state.presentation = payload
    }
  },

  plugins: [
    persistedStorage({
      key: 'tests',
      paths: ['testsStatuses']
    })
  ]
})

export default store

<template>
  <div class="page-main-wrapper">
    <div class="page page--main">
      <div class="page-inner">
        <Hero
          :current-event="currentEventStaticData"
          :user="user"
          :day="liveDay"
        />

        <Broadcast :presentation="presentation" />

        <template v-if="activeEvent">
          <Information />
        </template>

        <Knowledge
          v-if="settings && settings.test.enabled"
          :items="tests"
          @finishTest="onFinishTest"
        />
        <template v-if="banner1">
          <Banner
            class="page__banner1"
            :data="banner1"
            image-position="right"
          />
        </template>

        <template v-if="isForum22">
          <div class="focus-possibility__container">
            <v-container>
              <div class="focus-possibility">
                FOCUS <br>ON POSSIBILITIES
              </div>
            </v-container>
          </div>
          <div class="focus-possibility__container--mobile">
            <v-container>
              <div class="focus-possibility">
                OCEAN OF <br>POSSIBILITIES
              </div>
            </v-container>
          </div>
        </template>

        <Schedule v-if="settings && settings.schedule && settings.schedule.enabled" :items="schedules" />

        <!-- <v-lazy
          :options="{
            threshold: .5
          }"
          transition="fade-transition"
        > -->
        <Speakers v-if="settings && settings.speakers.enabled" :items="speakers" />
        <!-- </v-lazy> -->

        <template v-if="banner2">
          <Banner
            :data="banner2"
            class="page__banner2"
          />
        </template>

        <NewRanks
          v-if="settings && settings.nominations && settings.nominations.enabled"
          :items="ranks"
          :is-forum22="isForum22"
          :current-event="currentEventStaticData"
        />

        <UsefulMaterial v-if="settings && settings.materials.enabled" :items="materials" />
      </div>
    </div>
  </div>
</template>

<script>
import Hero from '@/modules/PageDay/components/Hero'
import Broadcast from './components/Broadcast'
import Knowledge from './components/Knowledge'
import Schedule from './components/Schedule'
import Speakers from './components/Speakers'
import NewRanks from './components/NewRanks'
import Information from './components/Information'
import Banner from './components/Banner'
import UsefulMaterial from './components/UsefulMaterial'
import store from './store'
import { mapActions, mapState } from 'vuex'
import EventBus from '@/EventBus'
import userService from '@/services/user'
import businessEventService from '@/services/businessEvent'
import { SETTINGS_HOME_PAGE_KEY } from '@/constants/settings'
import { getCurrentEventStaticData } from '@/utils/common'
import { THEME_FORUM_22 } from '@/constants/events'

export default {
  store,

  components: {
    Hero,
    Broadcast,
    Knowledge,
    Schedule,
    Speakers,
    NewRanks,
    UsefulMaterial,
    Information,
    Banner
  },

  data: () => ({
    user: {},
    liveDay: null,
    settings: null,
    activeEvent: null,
    forum21EventId: process.env.VUE_APP_FORUM21_EVENT_ID,
    currentEventStaticData: getCurrentEventStaticData()
  }),

  computed: {
    ...mapState(['materials', 'tests', 'schedules', 'speakers', 'presentation', 'ranks']),

    banner1 () {
      return this.currentEventStaticData && this.currentEventStaticData.banner1
    },
    banner2 () {
      return this.currentEventStaticData && this.currentEventStaticData.banner2
    },
    isForum22 () {
      return this.currentEventStaticData && this.currentEventStaticData.name === THEME_FORUM_22
    }
  },

  async created () {
    this.onLangUpdate = this.onLangUpdate.bind(this)
    this.onTestsUpdate = this.onTestsUpdate.bind(this)
    this.onLangUpdate = this.onLangUpdate.bind(this)

    this.getContent()
    this.subscribeOnPresentationChange()

    EventBus.subscribe('language:update', this.onLangUpdate)

    this.user = await userService.getUser()
    this.liveDay = await businessEventService.getActiveDay().then(res => res?.index)
    this.activeEvent = await businessEventService.getActiveEvent()
    this.currentEventStaticData = getCurrentEventStaticData(this.activeEvent.id)

    this.normalizeSettings(await businessEventService.getSettings(SETTINGS_HOME_PAGE_KEY))

    if (!this.$cookies.get('tour_skipped')) {
      setTimeout(() => {
        this.$tours.tour.start()
      }, 1000)
    }

    EventBus.subscribe('ws:quiz_service', this.onTestsUpdate)
    EventBus.subscribe('businessEvent:update', this.onEventUpdate)
  },

  beforeDestroy () {
    EventBus.unsubscribe('businessEvent:update', this.onEventUpdate)
    EventBus.unsubscribe('language:update', this.onLangUpdate)
    EventBus.unsubscribe('ws:quiz_service', this.onTestsUpdate)
  },

  methods: {
    normalizeSettings (obj) {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const tempKey = obj[key].name
          if (tempKey !== key) {
            const temp = obj[key]
            delete obj[key]
            obj[tempKey] = temp
          }
        }
      }
      this.settings = { ...obj }
    },
    ...mapActions([
      'getContent',
      'subscribeOnPresentationChange',
      'updateTestStatus',
      'getTests'
    ]),

    async onEventUpdate () {
      this.liveDay = await businessEventService.getActiveDay().then(res => res?.index)
    },

    async onLangUpdate () {
      await this.getContent()
      await this.subscribeOnPresentationChange()
    },

    onTestsUpdate () {
      this.getTests()
    },

    onFinishTest ({ status, test }) {
      this.updateTestStatus({ status, test })
    }
  }
}
</script>

<style lang="scss" scoped>
.page__banner1 {
  margin-top: 90px;

  @media screen and (max-width: 600px) {
    margin-top: 50px;
  }
}
</style>






















































































import Vue, { PropType } from 'vue'
import moment from 'moment'
import { upperFirst } from 'lodash'
import sanitizeHtml from 'sanitize-html'
import { ChatQuizAnswer, ChatQuizMessage } from '@/modules/ExpertChat/types'

export default Vue.extend({
  filters: {
    dateTime (date: string) {
      return moment(date).fromNow()
    },

    upperFirst
  },

  props: {
    value: {
      type: Object as PropType<ChatQuizMessage>,
      default: () => ({} as ChatQuizMessage)
    }
  },

  data: () => ({
    vote: null as ChatQuizMessage['userVote']
  }),

  computed: {
    voteReadOnly (): boolean {
      return !!this.value.userVote
    }
  },

  created () {
    this.vote = this.value.userVote
  },

  methods: {
    toVote () {
      this.$emit('submit', {
        quiz: this.value,
        vote: this.vote
      })
    },

    getPercentVotesOfTotal (answerVotes: ChatQuizAnswer['votes']) {
      if (!this.value.payload.totalVotes) return 0
      return answerVotes / this.value.payload.totalVotes * 100
    },

    sanitizeHtml
  }
})

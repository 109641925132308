var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "eventsList", staticClass: "expert-chat__event-list" },
    [
      _vm.limitedItems.length
        ? _c("div", {
            directives: [
              {
                name: "intersect",
                rawName: "v-intersect",
                value: {
                  handler: _vm.onOldestBorder,
                  options: _vm.intersectOptions
                },
                expression:
                  "{\n      handler: onOldestBorder,\n      options: intersectOptions\n    }"
              }
            ],
            staticClass: "oldestBorder"
          })
        : _vm._e(),
      _vm._l(_vm.limitedItems, function(event) {
        return [
          event.type === "text"
            ? _c("ChatEventText", {
                key: event.id,
                staticClass: "expert-chat__event-list-item",
                attrs: {
                  id: event.id,
                  value: event,
                  "current-user": _vm.currentUser,
                  "enable-translate": _vm.enableTranslateMessages
                },
                on: {
                  resend: function($event) {
                    return _vm.$emit("resend", $event)
                  },
                  contextmenu: function($event) {
                    return _vm.onContextMenu($event, event)
                  }
                }
              })
            : _vm._e(),
          event.type === "quiz"
            ? _c("ChatEventQuiz", {
                key: event.id,
                staticClass: "expert-chat__event-list-item",
                attrs: { id: event.id, value: event },
                on: {
                  submit: function($event) {
                    return _vm.$emit("vote", $event)
                  },
                  contextmenu: function($event) {
                    return _vm.onContextMenu($event, event)
                  }
                }
              })
            : _vm._e()
        ]
      }),
      _vm.limitedItems.length
        ? _c("div", {
            directives: [
              {
                name: "intersect",
                rawName: "v-intersect",
                value: {
                  handler: _vm.onNewestBorder,
                  options: _vm.intersectOptions
                },
                expression:
                  "{\n      handler: onNewestBorder,\n      options: intersectOptions\n    }"
              }
            ],
            staticClass: "newestBorder"
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
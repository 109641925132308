<template>
  <section v-if="!loading" class="question">
    <p class="question__head">
      {{ $t('question') | upperFirst }} {{ currentQuestionNumber + 1 }} / {{ questions.length }}
    </p>

    <h3 class="question__title">
      {{ currentQuestion.text }}
    </h3>

    <div class="questions-list">
      <div
        v-for="(el, idx) in currentQuestion.answerOptions"
        :key="idx"
        class="questions-list__item"
        :class="{right: selectedAnswer && isRightAnswer(idx), wrong: selectedAnswer && (idx + 1) === selectedAnswer}"
        @click="setAnswer(idx)"
      >
        <p class="item__index">
          .0{{ idx + 1 }}
        </p>

        <p class="item__title">
          {{ el.text }}
        </p>

        <p v-if="selectedAnswer && selectedAnswer === idx + 1" class="item__status">
          {{ isRightAnswer(idx) ? `${$t('right')}!` : `${$t('wrong')}!` }}
        </p>

        <v-icon
          v-if="selectedAnswer && selectedAnswer === idx + 1"
          class="item__status-icon"
          :dark="isRightAnswer(idx)"
          :color="isRightAnswer(idx) ? '#fff' : '#D156FA'"
        >
          {{ isRightAnswer(idx) ? 'mdi-check' : 'mdi-close' }}
        </v-icon>
      </div>
    </div>

    <div class="buttons">
      <v-btn
        class="next-btn custom-next"
        :class="{ 'active-btn': selectedAnswer}"
        :disabled="!selectedAnswer"
        @click="nextQuestion"
      >
        {{ isAllAnswers ? $t('result') : $t('next question') }}
      </v-btn>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Question',

  props: {
    questions: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      loading: true,
      currentQuestion: null,
      currentQuestionNumber: 0,
      currentRightAnswer: null,
      selectedAnswer: null,
      answers: []
    }
  },

  computed: {
    isAllAnswers () {
      return this.selectedAnswer && (this.currentQuestionNumber + 1) === this.questions.length
    }
  },

  watch: {
    currentQuestionNumber () {
      this.currentQuestion = this.questions[this.currentQuestionNumber]
    }
  },

  mounted () {
    this.currentQuestion = this.questions[this.currentQuestionNumber]
    this.loading = false
  },

  methods: {
    nextQuestion () {
      if (this.currentQuestionNumber + 1 < this.questions.length && this.selectedAnswer) {
        this.currentQuestionNumber += 1
        this.selectedAnswer = null
      } else if (this.currentQuestionNumber + 1 === this.questions.length && this.selectedAnswer) {
        this.$emit('finishTest', this.answers)
      }
    },

    setAnswer (idx) {
      if (!this.selectedAnswer) {
        this.selectedAnswer = idx + 1
      }

      if (this.isRightAnswer(idx)) {
        this.answers.push(true)
      } else {
        this.answers.push(false)
      }
    },
    isRightAnswer (idx) {
      return this.currentQuestion.answerOptions[idx].correct
    }
  }
}
</script>
<style lang="scss">
.active-btn {
  // background: var(--test-button) no-repeat center;
  background: linear-gradient(45deg, var(--main-primary-background-color), var(--main-secondary-background-color));
  background-size: cover;
  opacity: 1;
  color: #fff;
}
</style>
<style scoped lang="scss">
.question {
  padding: 32px 60px 33px 49px;

  &__head {
    opacity: 0.3;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
}

.questions-list {
  margin-top: 40px;

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    margin-bottom: -1px;
    cursor: pointer;
    box-sizing: border-box;
    transition: 0.3s;
    border: 1px solid #BEBEBE;

    p {
      line-height: 22px;
      opacity: 0.3;
      margin: 0;
    }

    .item__index {
      color: var(--main-primary-background-color);
      opacity: 0.9;
    }

    .item__title {
      opacity: 0.9;
      margin: 0 auto 0 18px;
    }

    .item__status {
      display: block;
      color: var(--main-primary-background-color);
      opacity: 0.9;
    }

    .item__status-icon {
      display: none;
    }

    &:not(:first-child),
    &:not(:last-child) {
      border-bottom: 1px solid #BEBEBE;
      border-top: 1px solid #BEBEBE;
    }

    &:first-child {
      border-radius: 15px 15px 0 0;
    }

    &:last-child {
      border-radius: 0 0 15px 15px;
    }

    &:hover {
      background-color: #E0E0E0;
    }
  }

  .right {
    background: linear-gradient(90deg, #82D4FB 0%, var(--dark_purple) 100%);

    .item__index,
    .item__title,
    .item__status {
      color: #fff;
      opacity: 0.9;
    }
  }

  .wrong {
    background-color: rgba(37, 37, 37, 0.05);
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.next-btn {
  width: 220px;
  height: 60px !important;
  border-radius: 15px;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark_blue);
  opacity: 0.6;
  margin-top: 42px;
  cursor: pointer;
  background-color: #F7F7FE;
}

@media screen and (max-width: 600px) {
  .question {
    padding: 30px 20px 20px;
  }

  .questions-list {
    margin-top: 35px;

    &__item {
      .item__title {
        margin: 0 auto 0 13px;
      }

      .item__status {
        display: none;
      }

      .item__status-icon {
        display: block;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  .next-btn {
    width: 100%;
  }

  .active-btn {
    background: linear-gradient(90deg, var(--blue) 0%, var(--dark_purple) 100%);
    opacity: 1;
    color: #fff;
  }
}
</style>

<template>
  <div>
    <c-img
      id="video-previev"
      v-intersect="onIntersect"
      src="/assets/img/theme/broadcast-plug.jpg"
      alt=""
    />
  </div>
</template>

<script>
import EventBus from '@/EventBus'

export default {
  props: {
    playerId: {
      type: String,
      default: ''
    }
  },

  updated () {
    this.emitData()
  },

  mounted () {
    this.emitData()
  },

  methods: {
    emitData () {
      EventBus.emit('videoPlayer:showPage', this.$route.params?.day)
    },

    onIntersect (entries, observer, isIntersecting) {
      EventBus.emit('videoPlayer:isIntersectingContainer', {
        day: this.$route.params?.day,
        isIntersecting
      })
    }
  }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      _vm._b(
        { staticClass: "expert-chat-event-quiz" },
        "div",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "expert-chat-event-quiz__info" }, [
        _c("div", { staticClass: "expert-chat-event-quiz__name" }, [
          _vm._v(" " + _vm._s(_vm._f("upperFirst")(_vm.$t("poll"))) + " ")
        ]),
        _c("div", { staticClass: "expert-chat-event-quiz__time" }, [
          _vm._v(" " + _vm._s(_vm._f("dateTime")(_vm.value.createdAt)) + " ")
        ])
      ]),
      _c(
        "div",
        { staticClass: "expert-chat-event-quiz__content" },
        [
          _c("div", {
            staticClass: "expert-chat-event-quiz__question",
            domProps: {
              innerHTML: _vm._s(_vm.sanitizeHtml(_vm.value.payload.text))
            }
          }),
          _c(
            "v-radio-group",
            {
              staticClass: "expert-chat-event-quiz__answers",
              class: {
                "expert-chat-event-quiz__answers--readonly": _vm.voteReadOnly
              },
              attrs: { readonly: _vm.voteReadOnly },
              model: {
                value: _vm.vote,
                callback: function($$v) {
                  _vm.vote = $$v
                },
                expression: "vote"
              }
            },
            _vm._l(_vm.value.payload.answers, function(answer, index) {
              return _c("v-radio", {
                key: index,
                staticClass: "expert-chat-event-quiz__answer",
                attrs: {
                  value: answer.id,
                  readonly: _vm.voteReadOnly,
                  color: "#2064FB",
                  "on-icon": "mdi-checkbox-blank-circle",
                  "off-icon": "mdi-checkbox-blank-circle",
                  ripple: !_vm.voteReadOnly
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "expert-chat-event-quiz__answer-label"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "expert-chat-event-quiz__answer-label-text"
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(answer.text))]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm
                                          .getPercentVotesOfTotal(answer.votes)
                                          .toFixed(2)
                                      ) + "%"
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "expert-chat-event-quiz__answer-label-progress"
                                },
                                [
                                  _c("v-progress-linear", {
                                    attrs: {
                                      value: _vm.getPercentVotesOfTotal(
                                        answer.votes
                                      ),
                                      "background-opacity": 0,
                                      height: 5,
                                      rounded: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          ),
          !_vm.voteReadOnly
            ? _c(
                "div",
                { staticClass: "expert-chat-event-quiz__actions text-right" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "expert-chat-btn",
                      attrs: {
                        disabled: !_vm.vote,
                        color: "primary",
                        elevation: "2",
                        small: ""
                      },
                      on: { click: _vm.toVote }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("to vote")) + " ")]
                  )
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "expert-chat-event-quiz__actions text-right" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$tc("vote", _vm.value.payload.totalVotes)) +
                      " "
                  )
                ]
              )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
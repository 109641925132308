














































import Vue, { PropType } from 'vue'
import ChatEventText from '@/modules/ExpertChat/components/ChatEventText.ts.vue'
import ChatEventQuiz from '@/modules/ExpertChat/components/ChatEventQuiz.ts.vue'
import { ChatMessage } from '@/modules/ExpertChat/types'

export default Vue.extend({
  components: {
    ChatEventText,
    ChatEventQuiz
  },

  props: {
    items: {
      type: Array as PropType<ChatMessage[]>,
      default: (): ChatMessage[] => ([])
    },

    enableTranslateMessages: {
      type: Boolean as PropType<boolean>,
      default: false
    },

    currentUser: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    offset: 0,
    limit: 20,
    intersectOptions: {
      threshold: 0
    } as IntersectionObserverInit,
    intersectTop: false,
    intersectBottom: false
  }),

  computed: {
    limitedItems (): ChatMessage[] {
      return this.items.slice().reverse()

      // FIXME: какое-то нерабочее дерьмо с лимитами и смещениями
      // eslint-disable-next-line no-unreachable
      const startIndex = this.items.length - (this.offset + this.limit)
      const endIndex = startIndex + this.limit

      return [...this.items].reverse().slice(startIndex, endIndex)
    }
  },

  watch: {
    items (value, prev) {
      // FIXME: Какой-то жуткий хак для прокрутки истории вниз после получения нового сообщения.
      if (!prev.length) {
        this.scrollDown()
      } else if (value.length) {
        const currentTime = new Date(value[0].createdAt)
        const previousTime = new Date(prev[0].createdAt)
        if (currentTime > previousTime) {
          this.scrollDown()
        }
      }
    },

    enableTranslateMessages () {
      // FIXME: Прокрутить историю вниз после переключения вкладок чата.
      this.scrollDown()
    },

    intersectTop (value) {
      if (value) {
        const eventsList = this.$refs.eventsList as HTMLElement | null
        if (!eventsList) return

        const itemsElements = this.$el.querySelectorAll('.expert-chat__event-list-item')
        const firstItemElement = itemsElements[0] as HTMLElement
        if (!firstItemElement) return
        const startScrollTop = eventsList.scrollTop
        this.incrementLimit()

        this.$nextTick(() => {
          eventsList.scrollTop = firstItemElement.offsetTop - startScrollTop
        })
      }
    },

    intersectBottom (value) {
      if (value) {
        const eventsList = this.$refs.eventsList as HTMLElement | null
        if (!eventsList) return

        const itemsElements = this.$el.querySelectorAll('.expert-chat__event-list-item')
        const lastItemElement = itemsElements[itemsElements.length - 1] as HTMLElement
        if (!lastItemElement) return
        const startOffsetTop = lastItemElement.offsetTop

        this.decrementLimit()

        this.$nextTick(() => {
          eventsList.scrollTop -= startOffsetTop - lastItemElement.offsetTop
        })
      }
    }
  },

  methods: {
    onContextMenu (event: MouseEvent, chatEvent: ChatMessage) {
      this.$emit('contextmenu', event, chatEvent)
    },

    incrementLimit (): boolean {
      if (!this.items.length) return false

      const newOffset = this.offset + Math.floor(this.limit / 2)
      const maxOffset = this.items.length - this.limit

      if (newOffset > maxOffset) {
        this.offset = maxOffset
        return false
      } else {
        this.offset = newOffset
        return true
      }
    },

    decrementLimit (): boolean {
      if (!this.items.length) return false

      const newOffset = this.offset - Math.floor(this.limit / 2)
      const minOffset = 0

      if (newOffset < minOffset) {
        this.offset = minOffset
        return false
      } else {
        this.offset = newOffset
        return true
      }
    },

    onOldestBorder (entries: never, observer: never, isIntersect: boolean) {
      this.intersectTop = isIntersect
    },

    onNewestBorder (entries: never, observer: never, isIntersect: boolean) {
      this.intersectBottom = isIntersect
    },

    scrollDown () {
      // Сбросить к исходным значениям для синхронизации с прокруткой.
      this.limit = 20
      this.offset = 0

      const eventsList = this.$refs.eventsList as HTMLElement
      this.$nextTick(() => eventsList.scrollTo(0, eventsList.scrollHeight))
    }
  }
})

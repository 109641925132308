




































import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    valueInput: {
      get (): boolean {
        return this.value
      },
      set (val: boolean) {
        this.$emit('input', val)
      }
    }
  }
})

<template>
  <section class="section-mp-broadcast">
    <div class="mp-broadcast-container">
      <v-container>
        <div class="row">
          <div class="col-12">
            <div v-if="presentation.isPresentationShown" class="mp-broadcast-block">
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="broadcast-video-block">
                    <div class="broadcast-video">
                      <PlayerContainer />
                    </div>
                  </div>
                </div>

                <div class="col-12 col-lg-6">
                  <div class="broadcast-chat-block">
                    <Presentation class="broadcast-chat" />

                    <div v-show="chatAvailable" class="chat-block-wrapper">
                      <button
                        class="chat-open-link"
                        :class="{active: chatOpen}"
                      >
                        <template v-if="newMessages">
                          <span>
                            {{ $tc('new comment', newMessages) }}
                          </span>

                          <span class="chat-open-link-new" />
                        </template>

                        <template v-else>
                          {{ $t('chat users tab text') }}
                        </template>

                        <!-- eslint-disable-next-line vue/html-self-closing -->
                        <div
                          class="chat-open-link__arrow"
                          @click="chatOpen = !chatOpen"
                        >
                        </div>
                      </button>

                      <v-expand-transition>
                        <div v-show="chatOpen" class="chat-block">
                          <keep-alive>
                            <ExpertChat
                              ref="collapsedChat"
                              :visible="chatOpen"
                              :is-presentation-shown="presentation.isPresentationShown"
                              @unreadMessages:update="onUnreadMessages"
                            />
                          </keep-alive>
                        </div>
                      </v-expand-transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-else
              class="mp-broadcast-block mp-broadcast-block--no-presentation"
            >
              <div class="row justify-content-center">
                <div class="col-12 col-lg-8">
                  <div class="broadcast-video-block">
                    <div class="broadcast-video">
                      <PlayerContainer />
                    </div>
                  </div>
                </div>

                <div v-show="chatAvailable" class="col-12 col-lg-4">
                  <div class="broadcast-chat-block">
                    <div class="chat-block-wrapper">
                      <div class="chat-block">
                        <keep-alive>
                          <ExpertChat @unreadMessages:update="onUnreadMessages" />
                        </keep-alive>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </section>
</template>

<script>
import ExpertChat from '@/modules/ExpertChat/index.ts'
import PlayerContainer from '@/components/Player/PlayerContainer'
import EventBus from '@/EventBus'
import Presentation from '@/modules/PageDay/containers/Presentation'

export default {
  components: {
    ExpertChat,
    PlayerContainer,
    Presentation
  },

  props: {
    presentation: {
      type: Object,
      default: () => ({
        isPresentationShown: false,
        slide: null
      })
    }
  },

  data: () => ({
    newMessages: 0,
    chatAvailable: false,
    chatOpen: false
  }),

  watch: {
    chatOpen (val) {
      if (val) {
        this.$refs.collapsedChat.scrollDown()
      }
    },

    chatAvailable () {
      if (!this.presentation.isPresentationShown) {
        this.$nextTick(() => EventBus.emit('videoPlayer:presentationResize'))
      }
    },

    'presentation.isPresentationShown' () {
      this.$nextTick(() => EventBus.emit('videoPlayer:presentationResize'))
    }
  },

  created () {
    EventBus.subscribe('chat:available', this.onChatStatusUpdate)
  },

  destroyed () {
    EventBus.unsubscribe('chat:available', this.onChatStatusUpdate)
  },

  methods: {
    onUnreadMessages (val) {
      this.newMessages = val
    },

    onChatStatusUpdate (value) {
      this.chatAvailable = Boolean(value)
    }
  }
}
</script>



































































import Vue, { PropType } from 'vue'
import moment from 'moment'
import translate from '@/utils/translate'
import sanitizeHtml from 'sanitize-html'
import { ChatTextMessage } from '@/modules/ExpertChat/types'

export default Vue.extend({
  filters: {
    dateTime (date: string) {
      return moment(date).fromNow()
    }
  },

  props: {
    value: {
      type: Object as PropType<ChatTextMessage>,
      default: () => ({} as ChatTextMessage)
    },

    enableTranslate: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  data () {
    return {
      translateLoading: false,
      translatedText: ''
    }
  },

  computed: {
    myMessage () {
      return {
        'my-message': this.value.isCurrentUserOwner
      }
    },
    isLink () {
      const URL = /(https:|http:|www\.)\S*/g
      const message: string = this.translatedText ? this.translatedText : this.value.payload.text

      return message?.replace(URL,
        match => `<a href="${match}" target="_blank">${match}</a>`)
    }
  },

  methods: {
    sanitizeHtml,

    async translateMessage () {
      if (this.translatedText) {
        this.translatedText = ''
        return
      }

      try {
        this.translateLoading = true
        const userLang = localStorage.getItem('public:language')
        this.translatedText = await translate(this.value.payload.text, userLang)
      } finally {
        this.translateLoading = false
      }
    }
  }
})

<template>
  <section class="section-useful-material">
    <div class="useful-material-container">
      <v-container>
        <div class="row">
          <div class="col-12">
            <div class="useful-material-top-block">
              <div class="sect-count">
                .06
              </div>

              <h2>
                {{ $t('useful materials') | upperFirst }}
              </h2>
            </div>
          </div>
        </div>
      </v-container>

      <v-container class="useful-material-slider-block-container">
        <div class="useful-material-slider-block">
          <Slick
            ref="carousel"
            :options="sliderSettings"
            class="useful-material-slider slider"
          >
            <div
              v-for="(item, index) in items"
              :key="'' + item.title + index"
              class="useful-material-slide slide"
            >
              <div class="useful-material-s-item">
                <div class="useful-material-s-item__inner">
                  <div class="useful-material-s-item__top">
                    <div class="useful-material-s-item__count">
                      .0{{ index+1 }}
                    </div>
                  </div>

                  <div class="useful-material-s-item__content">
                    <h3>
                      {{ item.title | upperFirst }}
                    </h3>

                    <div class="useful-material-s-item__text">
                      {{ item.description }}
                    </div>
                  </div>

                  <div class="useful-material-s-item__bottom">
                    <a
                      :href="item.link"
                      target="_blank"
                      class="useful-material-s-item__btn blue-gr-btn"
                    >
                      {{ $t('download') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Slick>
        </div>
      </v-container>
    </div>
  </section>
</template>

<script>

import Slick from 'vue-slick'

export default {
  components: { Slick },

  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },

  data: () => ({
    sliderSettings: {
      slidesToShow: 3,
      arrows: true,
      dots: false,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            arrows: true,
            slidesToShow: 2
          }
        },
        {
          breakpoint: 992,
          settings: {
            arrows: true,
            slidesToShow: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            variableWidth: true,
            centerPadding: '0px',
            slidesToShow: 1
          }
        }
      ]
    }
  }),

  watch: {
    items () {
      const currIndex = this.$refs.carousel.currentSlide()

      this.$refs.carousel.destroy()
      this.$nextTick(() => {
        this.$refs.carousel.create()
        this.$refs.carousel.goTo(currIndex, true)
      })
    }
  }
}
</script>

<template>
  <section v-if="!loading" class="start">
    <div class="block">
      <div class="left">
        <p class="description">
          {{ test.description }}
        </p>
      </div>
    </div>

    <div class="start-btn" @click="$emit('startTest')">
      {{ $t('start test') }}
    </div>
  </section>
</template>

<script>
export default {
  name: 'Start',

  props: {
    test: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      loading: true
    }
  },

  mounted () {
    this.loading = false
  }
}
</script>

<style scoped lang="scss">
.start {
  padding: 58px 50px 39px;
}

.block {
  display: flex;
}

.left {
  margin-right: 53px;
}

.description {
  opacity: 0.8;
  max-width: 370px;
}

.test-from {
  opacity: 0.3;
}

.author {
  display: flex;

  &-img {
    width: 50px;
    margin-right: 16px;
  }
}

.start-btn {
  // background: var(--test-button) no-repeat center;
  background: linear-gradient(45deg, var(--main-primary-background-color), var(--main-secondary-background-color));
  background-size: cover;
  width: 220px;
  height: 60px;
  border-radius: 20px;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 42px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .start {
    padding: 33px 22px 20px;
  }

  .block {
    flex-direction: column;
  }

  .left {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .description {
    max-width: 100%;
  }

  .author {
    &-name {
      max-width: 160px;
    }
  }

  .start-btn {
    width: 100%;
    margin-top: 35px;
  }
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("section", { staticClass: "start" }, [
        _c("div", { staticClass: "block" }, [
          _c("div", { staticClass: "left" }, [
            _c("p", { staticClass: "description" }, [
              _vm._v(" " + _vm._s(_vm.test.description) + " ")
            ])
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "start-btn",
            on: {
              click: function($event) {
                return _vm.$emit("startTest")
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("start test")) + " ")]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }



























































































































































import Vue, { PropType } from 'vue'
import store from './store'
import { mapActions, mapState, mapMutations, ActionPayload } from 'vuex'
import TextForm from './components/TextForm.ts.vue'
import EventBus from '@/EventBus'
import userService from '@/services/user'
import ConfirmDelete from './components/dialogs/ConfirmDelete.ts.vue'
import ChatEventsList from './components/ChatEventsList.ts.vue'
import { cloneDeep } from 'lodash'
import { ChatMessage, ChatQuizMessage, ChatTextMessage } from '@/modules/ExpertChat/types'
import { UserInstance } from '@/services/user/types'
import * as authService from '@/services/auth'
import {
  ROLE_WRITE_COMMON_CHAT
} from '@/constants/roles'

export default Vue.extend({
  components: {
    ChatEventsList,
    TextForm,
    ConfirmDelete
  },

  store,

  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: true
    },

    isPresentationShown: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  data: () => ({
    message: '',
    chatTabs: [
      {
        id: 'expert',
        label: 'chat expert tab text'
      },
      {
        id: 'shared',
        label: 'chat users tab text'
      }
    ],
    activeChatTabIndex: 0,
    formDialog: false,
    beingEdited: null as null | ChatMessage,
    confirmDeleteDialog: false,
    menu: {
      showMenu: false,
      x: 0,
      y: 0
    },
    contextMenuChatEvent: null as null | ChatMessage,
    currentUser: {} as { id: string },
    unreadMessages: [] as string[],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    unsubscribeAction: () => {},
    isUpdateMessageLoading: false,
    isDeleteMessageLoading: false,
    notificationTimeout: window.setTimeout(() => ({}), 0),
    notificationVisible: false
  }),

  computed: {
    ...mapState([
      'messages',
      'sharedMessages'
    ]),

    currentChat () {
      return this.chatTabs[this.activeChatTabIndex].id
    },

    disableEditChatEvent (): boolean {
      return !this.contextMenuChatEvent || !this.contextMenuChatEvent?.isCurrentUserOwner
    },

    unreadMessagesCount (): number {
      return this.unreadMessages.length
    },

    disableCommonChat (): boolean {
      const currentRoles = authService.getRoles()
      console.info(currentRoles.includes(ROLE_WRITE_COMMON_CHAT))
      return !currentRoles.includes(ROLE_WRITE_COMMON_CHAT)
    }
  },

  watch: {
    formDialog (val: boolean) {
      if (!val) {
        /**
         * Дать отработать анимации
         * */
        setTimeout(() => {
          this.beingEdited = null
        }, 500)
      }
    },

    currentChat (val: string) {
      if (val === 'shared' && this.disableCommonChat) {
        window.clearTimeout(this.notificationTimeout)
        this.notificationVisible = true
        this.notificationTimeout = window.setTimeout(() => {
          this.notificationVisible = false
        }, 4000)
      }
    },

    beingEdited (val: ChatMessage) {
      if (val) this.formDialog = true
    },

    unreadMessagesCount (val: number) {
      if (this.visible) {
        this.$emit('unreadMessages:update', 0)
        return
      }
      this.$emit('unreadMessages:update', val)
    },

    visible (newVal: boolean, oldVal: boolean) {
      if (newVal && newVal !== oldVal) {
        this.markAllUnreadMessagesAsRead()
      }
    }
  },

  async created () {
    this.unsubscribeAction = this.$store.subscribeAction({
      after: (action) => {
        if (action.type !== 'onReceiveNewMessage') return

        this.onMessage(action)
      }
    })

    this.currentUser = await userService.getUser()

    this.getHistory()
    this.getSharedMessagesHistory()

    this.onUserUpdate = this.onUserUpdate.bind(this)
    this.onLanguageUpdate = this.onLanguageUpdate.bind(this)

    // eslint-disable-next-line @typescript-eslint/unbound-method
    EventBus.subscribe('user:update', this.onUserUpdate)
    // eslint-disable-next-line @typescript-eslint/unbound-method
    EventBus.subscribe('language:update', this.onLanguageUpdate)
  },

  beforeDestroy () {
    this.clearState()
    this.unsubscribeAction()
    // eslint-disable-next-line @typescript-eslint/unbound-method
    EventBus.unsubscribe('user:update', this.onUserUpdate)
    // eslint-disable-next-line @typescript-eslint/unbound-method
    EventBus.unsubscribe('language:update', this.onLanguageUpdate)
  },

  methods: {
    ...mapActions([
      'getHistory',
      'getSharedMessagesHistory',
      'clearState',
      'sendMessage',
      'sendSharedMessage',
      'updateMessage',
      'updateSharedMessage',
      'deleteMessage',
      'deleteSharedMessage',
      'sendQuizVote'
    ]),

    ...mapMutations(['setSelectedRoom']),

    scrollDown () {
      const eventsList = this.$refs.eventsList as (Vue & { scrollDown: () => void }) | null

      if (eventsList) {
        eventsList.scrollDown()
      }
    },

    onUserUpdate (user: UserInstance) {
      if (!user?.id || this.currentUser?.id !== user?.id) {
        this.clearState()
      }

      if (user?.id) {
        this.currentUser = user
        this.getHistory()
      }
    },

    onLanguageUpdate () {
      this.clearState()
      this.getHistory()
    },

    onSendMessage () {
      const message = this.message.trim()
      if (!message) return

      if (this.currentChat === 'expert') {
        this.sendMessage(this.message)
      } else {
        this.sendSharedMessage(this.message)
      }

      this.message = ''
    },

    async onTextSubmit (val: ChatTextMessage) {
      try {
        this.isUpdateMessageLoading = true
        if (this.currentChat === 'expert') {
          await this.updateMessage(val)
        } else {
          await this.updateSharedMessage(val)
        }
        this.formDialog = false
      } finally {
        this.isUpdateMessageLoading = false
      }
    },

    async resendMessage (val: ChatTextMessage) {
      if (this.currentChat === 'expert') {
        await this.sendMessage(val)
      } else {
        await this.sendSharedMessage(val)
      }
    },

    toVote (payload: { quiz: ChatQuizMessage, vote: ChatQuizMessage['userVote'] }) {
      this.sendQuizVote({
        quiz: payload.quiz,
        vote: payload.vote
      })
    },

    onContextMenu (e: MouseEvent, chatEvent: ChatMessage) {
      e.preventDefault()
      this.menu.showMenu = false
      this.menu.x = e.clientX
      this.menu.y = e.clientY

      this.contextMenuChatEvent = chatEvent

      this.$nextTick(() => {
        this.menu.showMenu = true
      })
    },

    startEditEvent () {
      if (this.contextMenuChatEvent?.type === 'text') {
        this.beingEdited = cloneDeep(this.contextMenuChatEvent)
      }
    },

    async removeEvent () {
      try {
        this.isDeleteMessageLoading = true
        if (this.currentChat === 'expert') {
          await this.deleteMessage(this.contextMenuChatEvent)
        } else {
          await this.deleteSharedMessage(this.contextMenuChatEvent)
        }
        this.contextMenuChatEvent = null
        this.confirmDeleteDialog = false
      } finally {
        this.isDeleteMessageLoading = false
      }
    },

    onMessage (action: ActionPayload) {
      if (this.visible) {
        // this.markChatEventAsRead(message)
      } else {
        this.unreadMessages.push(action.payload.id)
      }
    },

    markAllUnreadMessagesAsRead () {
      if (!this.unreadMessages.length) return

      // const promises = this.unreadMessages.map(el => this.markChatEventAsRead(el))
      // await Promise.all(promises)
      this.unreadMessages = []
    }
  }
})

import { render, staticRenderFns } from "./ConfirmDelete.ts.vue?vue&type=template&id=1b13b7f3&"
import script from "./ConfirmDelete.ts.vue?vue&type=script&lang=ts&"
export * from "./ConfirmDelete.ts.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/nginx/coral/coral-public/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b13b7f3')) {
      api.createRecord('1b13b7f3', component.options)
    } else {
      api.reload('1b13b7f3', component.options)
    }
    module.hot.accept("./ConfirmDelete.ts.vue?vue&type=template&id=1b13b7f3&", function () {
      api.rerender('1b13b7f3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/ExpertChat/components/dialogs/ConfirmDelete.ts.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      {
        class: [
          "banner",
          { "banner--image-right": _vm.imagePosition === "right" }
        ],
        style: {
          backgroundImage:
            "url(" +
            _vm.backgroundImage +
            "), url(" +
            _vm.backgroundImageLocal +
            ")"
        }
      },
      [
        _vm.data.image
          ? _c("div", {
              staticClass: "banner__image",
              style: {
                backgroundImage:
                  "url(" + _vm.image + "), url(" + _vm.imageLocal + ")"
              }
            })
          : _vm._e(),
        _c("v-container", { staticClass: "banner__container" }, [
          _c("div", { staticClass: "banner__content" }, [
            _c("div", { staticClass: "banner__pretitle" }, [
              _vm._v(" " + _vm._s(_vm.$t(_vm.data.pretitle)) + " ")
            ]),
            _c("div", { staticClass: "banner__title" }, [
              _vm._v(" " + _vm._s(_vm.$t(_vm.data.title)) + " ")
            ]),
            _c("div", { staticClass: "banner__subtitle" }, [
              _vm._v(" " + _vm._s(_vm.$t(_vm.data.subtitle)) + " ")
            ]),
            _c(
              "a",
              {
                staticClass: "banner__button",
                attrs: { href: _vm.data.button.link, target: "_blank" }
              },
              [_vm._v(" " + _vm._s(_vm.$t(_vm.data.button.label)) + " ")]
            )
          ])
        ])
      ],
      1
    ),
    _c(
      "section",
      {
        class: [
          "banner-mobile",
          { "banner--image-right": _vm.imagePosition === "right" }
        ],
        style: {
          backgroundImage:
            "url(" +
            _vm.backgroundImageMobile +
            "), url(" +
            _vm.backgroundImageLocalMobile +
            ")"
        }
      },
      [
        _vm.data.image
          ? _c("div", {
              staticClass: "banner__image",
              style: {
                backgroundImage:
                  "url(" +
                  _vm.imageMobile +
                  "), url(" +
                  _vm.imageLocalMobile +
                  ")"
              }
            })
          : _vm._e(),
        _c("v-container", { staticClass: "banner__container" }, [
          _c("div", { staticClass: "banner__content" }, [
            _c("div", { staticClass: "banner__pretitle" }, [
              _vm._v(" " + _vm._s(_vm.$t(_vm.data.pretitle)) + " ")
            ]),
            _c("div", { staticClass: "banner__title" }, [
              _vm._v(" " + _vm._s(_vm.$t(_vm.data.title)) + " ")
            ]),
            _c("div", { staticClass: "banner__subtitle" }, [
              _vm._v(" " + _vm._s(_vm.$t(_vm.data.subtitle)) + " ")
            ]),
            _c(
              "a",
              {
                staticClass: "banner__button",
                attrs: { href: _vm.data.button.link, target: "_blank" }
              },
              [_vm._v(" " + _vm._s(_vm.$t(_vm.data.button.label)) + " ")]
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
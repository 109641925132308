import { render, staticRenderFns } from "./ChatEventText.ts.vue?vue&type=template&id=3ad1df2b&"
import script from "./ChatEventText.ts.vue?vue&type=script&lang=ts&"
export * from "./ChatEventText.ts.vue?vue&type=script&lang=ts&"
import style0 from "./ChatEventText.ts.vue?vue&type=style&index=0&lang=scss&scroped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/nginx/coral/coral-public/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ad1df2b')) {
      api.createRecord('3ad1df2b', component.options)
    } else {
      api.reload('3ad1df2b', component.options)
    }
    module.hot.accept("./ChatEventText.ts.vue?vue&type=template&id=3ad1df2b&", function () {
      api.rerender('3ad1df2b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/ExpertChat/components/ChatEventText.ts.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit($event)
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "text-capitalize" }, [
            _vm._v(" " + _vm._s(_vm.$t("message")) + " ")
          ]),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("message") + "*",
                  disabled: _vm.loading,
                  rules: [_vm.requiredRule]
                },
                model: {
                  value: _vm.localValue.payload.text,
                  callback: function($$v) {
                    _vm.$set(_vm.localValue.payload, "text", $$v)
                  },
                  expression: "localValue.payload.text"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    type: "submit",
                    loading: _vm.loading,
                    disabled: _vm.loading
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { disabled: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "section-useful-material" }, [
    _c(
      "div",
      { staticClass: "useful-material-container" },
      [
        _c("v-container", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "useful-material-top-block" }, [
                _c("div", { staticClass: "sect-count" }, [_vm._v(" .06 ")]),
                _c("h2", [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("upperFirst")(_vm.$t("useful materials"))) +
                      " "
                  )
                ])
              ])
            ])
          ])
        ]),
        _c(
          "v-container",
          { staticClass: "useful-material-slider-block-container" },
          [
            _c(
              "div",
              { staticClass: "useful-material-slider-block" },
              [
                _c(
                  "Slick",
                  {
                    ref: "carousel",
                    staticClass: "useful-material-slider slider",
                    attrs: { options: _vm.sliderSettings }
                  },
                  _vm._l(_vm.items, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: "" + item.title + index,
                        staticClass: "useful-material-slide slide"
                      },
                      [
                        _c("div", { staticClass: "useful-material-s-item" }, [
                          _c(
                            "div",
                            { staticClass: "useful-material-s-item__inner" },
                            [
                              _c(
                                "div",
                                { staticClass: "useful-material-s-item__top" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "useful-material-s-item__count"
                                    },
                                    [_vm._v(" .0" + _vm._s(index + 1) + " ")]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "useful-material-s-item__content"
                                },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("upperFirst")(item.title)
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "useful-material-s-item__text"
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.description) + " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "useful-material-s-item__bottom"
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "useful-material-s-item__btn blue-gr-btn",
                                      attrs: {
                                        href: item.link,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("download")) + " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }